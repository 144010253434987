import React from 'react';
import { Link } from 'react-router-dom';
import Ratings from './Ratings';
import server1008 from './images/JDTS-1008.jpg';
import server1014 from './images/JDTS-1014A,B,C,D,E.jpg';
import server1033 from './images/JDTS-1033.jpg';
import server1038 from './images/JDTS-1038A,B,C,D.jpg';
import server1043 from './images/JDTS-1043A,B,C,D.jpg';
import server1089 from './images/JDTS-1089.jpg';
import warranty from './images/ironclad-90-days-warranty.png'
import specialOffer from './images/special-offer.png';
import invest from './images/invest-less-save-more.png';
import discountImg from './images/Discount-PNG-Image (1).png'
import ProductNav from './ProductNav';
export default function ProductHome() {
    return (
        <>
            <ProductNav />
            
            <div className='pt-2 pb-5 product-page' style={{ background: 'rgb(244,244,244,.2)' }}>
                <div className='row ps-md-5 pe-md-2 my-5 p-2'>
                {window.innerWidth < 576 &&
                <div className='col-12'>HPE Proliant DL560 Gen9 Server
                    <i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-half text-warning"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='fw-bold text-primary-emphasis' style={{ fontSize: '13px' }}> <br />Brand HP</span>
                    <span className='fw-bolder ' style={{ fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;Status <span className='text-success'>&nbsp;&nbsp;IN STOCK &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="bi bi-heart fs-5 text-danger"></i><span className='text-dark'> Add to Whitlist</span><hr /></span></span>
                </div>
            }
                    <div className='col-md-4 col-12 mx-2 '>
                    { window.innerWidth >576 &&  <div className='position-relative fw-bold text-secondary' style={{ top: '1.2%', left: '-5%', fontSize: '13px' }}><Link className='history-link'>Home</Link>{'>'}<Link className='history-link'>Products</Link>{'>'}<Link className='history-link'>Servers</Link>{'>'}<Link className='history-link'>HPE Proliant DL560 Gen9 Server</Link></div>}
                       <div className='position-relative' style={{ top: '6.5%', left: '75%' }}><img className='shadow' src={discountImg} width='60' height='40' alt="" /></div>
                        <div className='mb-3 mt-4'>
                            <img className='rounded shadow' src={server1008} width='350' height="330" alt="" />
                        </div>
                        {window.innerWidth>576 &&<div className='me-5 container position-relative' style={{ top: '5%', left: '-25%' }}>
                            <img src={warranty} width='500' height="500" style={{ marginRight: '5rem' }} alt="" />
                        </div>}
                    </div>
                    <div className='col-md-5 col-12'>
                    { window.innerWidth<576 &&
                        <div className='col-md-2 col-12 p-0'>
                        <div className=' my-2 ' style={{ fontFamily: '"Amazon Ember", Arial, sans-serif', backgroundColor: 'rgb(255,255,255,.2)' }}>
                            <span className='fs-3 fw-bold' style={{ color: 'rgb(0,0,0)' }}><sup style={{ fontSize: '10px' }}>&#8377;</sup>15,00,000</span>
                            <span className='text-primary fw-bold' style={{ fontSize: '14px' }}><br />Free Delivery <span className='text-secondary fw-bold'>SUNDAY 21 April.</span></span>
                            <span className='fw-bold text-primary'> <br />Details</span><br />
                            <span>Order within <span className='fw-bold text-success'>24hours</span><br /></span>
                            <span className='text-primary-emphasis' style={{ fontSize: '14px' }}>Delevering to Delhi 110055 <Link style={{ textDecoration: 'none' }}>Updated location</Link><br /><br /></span>
                            <span className='fw-bold text-primary-emphasis'><span className='fw-bold '>Sold By</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JDTS<br /></span>
                            <div className='text-center'>
                                <Link to="/" className="btn-hover-bg btn btn-sm btn-warning rounded-pill text-white px-4 ms-lg-4 my-3 mx-2" style={{}}>&nbsp;&nbsp;Add to Cart&nbsp;&nbsp;</Link><br />
                                <Link to="/" className="btn-hover-bg btn btn-sm btn-primary rounded-pill text-white px-4 ms-lg-4 mb-3 mx-2" style={{ backgroundColor: ' ' }}>&nbsp;&nbsp;&nbsp;&nbsp;Buy Now&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                            </div>
                        </div>
                       {window.innerWidth>576 &&<img className='position-relative' src={specialOffer} width='50' height='50' style={{ top: '7%', left: '8%', zIndex: '10' }} alt="" />}

                       {window.innerWidth>576 && <div className='border position-relative rounded border-3 my-2'>

                            <img src={server1033} width='198' height='200' alt="" />
                        </div>}
                    </div>}
                        {window.innerWidth>576 &&<div><div><span className='fs-2'>HPE Proliant DL560 Gen9 Server</span></div>
                        <i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-half text-warning"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className='fw-bold text-primary-emphasis' style={{ fontSize: '13px' }}>Brand HP</span>
                        <span className='fw-bolder ' style={{ fontSize: '12px' }}>&nbsp;&nbsp;&nbsp;&nbsp;Status <span className='text-success'>&nbsp;&nbsp;IN STOCK &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="bi bi-heart fs-5 text-danger"></i><span className='text-dark'> Add to Whitlist</span><hr /></span></span>
                        <span className='fs-3 fw-bold ' style={{ fontFamily: '"Amazon Ember", Arial, sans-serif', color: '', lineHeight: '1' }}><sup>&#8377;</sup> 15,00,000</span>
                        <hr />
                        </div>}
                        <ul className='josefin-sans-heading text-secondary ps-1'>
                            <li style={{ listStyleType: 'none' }}>Max Supported RAM : 3 TB DDR4</li>
                            <li style={{ listStyleType: 'none' }}>Max Supported Storage : 92 TB</li>
                            <li style={{ listStyleType: 'none' }}>Supported Processor : Intel Xeon E5-4600 v4/v3 processors</li>
                            <li style={{ listStyleType: 'none' }}>Power Supply : 1200 W</li>
                            <li style={{ listStyleType: 'none' }}>Form Factor : 2U Rack Server</li>
                            <li style={{ listStyleType: 'none' }}>Processor : 31.3 Kg</li>
                            <li style={{ listStyleType: 'none' }}>DIMM Slots : 48 DIMMS</li>
                            <li style={{ listStyleType: 'none' }}>Warrenty : 1 Year</li>
                        </ul>
                        <div className='container mb-3 p-0' style={{ marginLeft: '' }}>
                            <button className=' btn-hover-bg rounded-0 btn btn-dark'><i class="bi bi-dash"></i></button><button className='btn btn-outline-dark fw-bold rounded-0' style={{ width: '90px' }}>&nbsp;&nbsp;&nbsp;0&nbsp;&nbsp;&nbsp;</button><button className='btn-hover-bg rounded-0 btn btn-dark'><i class="bi bi-plus"></i></button>
                        </div>
                        <span className='fw-normal'>Categories : <span className='text-primary fw-bold'>HP Refurbished Servers, HP Used Rack Servers, Refurbished Servers, Servers</span></span>
                        <hr />
                        <div className='fw-bold fs-5'><br />Reviews (5)</div>
                        <div className='' style={{ fontFamily: '"Amazon Ember", Arial, sans-serif', fontWeight: '540', textAlign: 'justify' }}><br />
                            <div className=''>
                                <div className='mb-3 bg-light rounded'><button className='text-white bg-primary disabled border-0 rounded' style={{ lineHeight: '' }}>5 <i class="bi bi-star-fill"></i></button>
                                    <span>&nbsp;&nbsp;&nbsp;Gautam Kumar, <span className='text-secondary-50'>Lathur, Marashtra</span></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className='border-0 rounded' style={{ background: 'light green', fontSize: '12px' }}><i class="bi bi-hand-thumbs-up-fill text-success"></i>response</button>
                                </div>
                                <div className='mb-3 bg-light rounded'><button className='text-white bg-primary disabled border-0 rounded' style={{ lineHeight: '' }}>5 <i class="bi bi-star-fill"></i></button>
                                    <span>&nbsp;&nbsp;&nbsp;Gautam Kumar, <span className='text-secondary-50'>Lathur, Marashtra</span></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className='border-0 rounded' style={{ background: 'light green', fontSize: '12px' }}><i class="bi bi-hand-thumbs-up-fill text-success"></i>response</button>
                                </div>
                                <div className='mb-3 bg-light rounded'><button className='text-white bg-primary disabled border-0 rounded' style={{ lineHeight: '' }}>5 <i class="bi bi-star-fill"></i></button>
                                    <span>&nbsp;&nbsp;&nbsp;Gautam Kumar, <span className='text-secondary-50'>Lathur, Marashtra</span></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className='border-0 rounded' style={{ background: 'light green', fontSize: '12px' }}><i class="bi bi-hand-thumbs-up-fill text-success"></i>response</button>
                                </div>
                                <div className='mb-3 bg-light rounded'><button className='text-white bg-primary disabled border-0 rounded' style={{ lineHeight: '' }}>5 <i class="bi bi-star-fill"></i></button>
                                    <span>&nbsp;&nbsp;&nbsp;Gautam Kumar, <span className='text-secondary-50'>Lathur, Marashtra</span></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className='border-0 rounded' style={{ background: 'light green', fontSize: '12px' }}><i class="bi bi-hand-thumbs-up-fill text-success"></i>response</button>
                                </div>
                                <div className='mb-3 bg-light rounded'><button className='text-white bg-primary disabled border-0 rounded' style={{ lineHeight: '' }}>5 <i class="bi bi-star-fill"></i></button>
                                    <span>&nbsp;&nbsp;&nbsp;Gautam Kumar, <span className='text-secondary-50'>Lathur, Marashtra</span></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button className='border-0 rounded' style={{ background: 'light green', fontSize: '12px' }}><i class="bi bi-hand-thumbs-up-fill text-success"></i>response</button>
                                </div>
                            </div>
                            <div className='' style={{ fontSize: '14px', fontWeight: '400' }}>
                                <br />Your rating for this product &nbsp;&nbsp;
                                <i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-fill text-warning"></i><i class="bi bi-star-half text-warning"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            {
                            }
                            <div className="form-floating">
                                <textarea className="form-control border-0 bg-light" placeholder="Leave a message here" id="message" style={{ height: "160px" }}></textarea>
                                <label htmlFor="message"><span style={{ fontSize: '12px' }}>Give Review....</span></label>
                            </div>
                        </div>
                    </div>
                    { window.innerWidth>576 &&<div className='col-md-2 col-12 ms-5 p-0'>
                        <div className='border rounded border-1 p-2 my-2 ' style={{ fontFamily: '"Amazon Ember", Arial, sans-serif', backgroundColor: 'rgb(255,255,255,.2)' }}>
                            <span className='fs-3 fw-bold' style={{ color: 'rgb(0,0,0)' }}><sup style={{ fontSize: '10px' }}>&#8377;</sup>15,00,000</span>
                            <span className='text-primary fw-bold' style={{ fontSize: '14px' }}><br />Free Delivery <span className='text-secondary fw-bold'>SUNDAY 21 April.</span></span>
                            <span className='fw-bold text-primary'> <br />Details</span><br />
                            <span>Order within <span className='fw-bold text-success'>24hours</span><br /></span>
                            <span className='text-primary-emphasis' style={{ fontSize: '14px' }}>Delevering to Delhi 110055 <Link style={{ textDecoration: 'none' }}>Updated location</Link><br /><br /></span>
                            <span className='fw-bold text-primary-emphasis'><span className='fw-bold '>Sold By</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JDTS<br /></span>
                            <div className=''>
                                <Link to="/" className="btn-hover-bg btn btn-sm btn-warning rounded-pill text-white px-4 ms-lg-4 my-3 mx-2" style={{ backgroundColor: ' ' }}>&nbsp;&nbsp;Add to Cart&nbsp;&nbsp;</Link>
                                <Link to="/" className="btn-hover-bg btn btn-sm btn-primary rounded-pill text-white px-4 ms-lg-4 mb-3 mx-2" style={{ backgroundColor: ' ' }}>&nbsp;&nbsp;&nbsp;&nbsp;Buy Now&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                            </div>
                        </div>
                       {window.innerWidth>576 &&<img className='position-relative' src={specialOffer} width='50' height='50' style={{ top: '7%', left: '8%', zIndex: '10' }} alt="" />}

                       {window.innerWidth>576 && <div className='border position-relative rounded border-3 my-2'>

                            <img src={server1033} width='198' height='200' alt="" />
                        </div>}
                    </div>}
                </div>
                <div className='container-fluid text-center fs-3 fw-bold' style={{ background: '', marginTop: '6rem' }}>Product Description</div>
                <div className='container-fluid p-5 text-secondary mb-5'>
                    The HPE ProLiant DL560 Gen9 Server is a high-performance 4-socket server designed to meet the needs of virtualization, database, business processing, and data-intensive workloads. It offers scalability with support for up to four Intel Xeon E5-4600 v3/v4 processors, providing massive compute power for demanding applications. With up to 6 TB of DDR4 SmartMemory (48 DIMM slots), it ensures ample memory capacity for large databases and virtualization environments. The server also offers various storage options, including hot-plug SFF and LFF drives, SAS, SATA, and NVMe SSDs, along with advanced storage management features with HPE Smart Array Controllers.
                    <br />
                    Engineered for performance, the DL560 Gen9 features Intel Turbo Boost Technology, Intel Hyper-Threading Technology, and DDR4 memory support, enabling faster data access and processing. It ensures reliability and data integrity with HPE SmartMemory and HPE SmartDrive technologies, as well as redundant power supplies and fans for increased system uptime. The server provides flexible networking options with multiple PCIe slots for HPE FlexibleLOM, allowing customization of network connectivity.
                    <br />
                    With HPE Integrated Lights-Out (iLO) management, the DL560 Gen9 offers remote management capabilities for easy server administration and monitoring. Security features like Secure Boot and Secure Encryption ensure data protection and system integrity. Optimized for virtualization, the server's high memory capacity, processing power, and virtualization features enable efficient consolidation and management of workloads. Available in a dense 2U form factor, it is suitable for data center environments where space is limited. Overall, the HPE ProLiant DL560 Gen9 Server offers a balanced combination of performance, scalability, reliability, and manageability for mission-critical applications and workloads.
                </div>
                <div className='row my-5'>
                    <div className='col-md-7 col-6'>
                        <iframe width="690" height="400" src="https://www.youtube.com/embed/xd94JrGVJvQ?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-4 col6'>
                        <img src={invest} width='400' height='400' alt="" />
                    </div>
                </div>
                <div className="container-fluid text-center fs-3 fw-bold pt-3" style={{ marginTop: '6rem', backgroundColor: '' }}>
                    Specifications and Additional Information
                </div>

                <div className='container-fluid p-5'>
                    <table className='info-table border border-1 shadow' style={{ backgroundColor: '#f4f4f4' }}>
                        <tr className='border-bottom border-1 p-2'>
                            <th className='border-end border-1'>Product type</th>
                            <th className='info'>HPE TU Server</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>Model</th>
                            <th className='info' style={{ width: '100%' }}>HPE Proliant DL560 G8</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>RAM Type</th>
                            <th className='info'>DDR3</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>Max RAM</th>
                            <th className='info'>1.5 TB</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>No of DIMM Slots</th>
                            <th className='info'>48</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>Max Storage</th>
                            <th className='info'>6 TB</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>Processor</th>
                            <th className='info'>
                                Intel Xeon processor E5-4600 or E5-4600 v2 product family</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>No of Processors</th>
                            <th className='info'>4</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>PCI Slots</th>
                            <th className='info'>6</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>RAID Controllers</th>
                            <th className='info'>HPE Smart Array P420i</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>Power Consumption (Wattage)</th>
                            <th className='info'>1200W</th>
                        </tr>
                        <tr className='border-bottom border-1'>
                            <th className='border-end border-1'>Power Supply</th>
                            <th className='info'>Dual</th>
                        </tr>
                    </table>
                </div>

                <div className='container-fluid text-center my-5 px-0' style={{ background: '', marginTop: '3rem' }}>
                    <span className='fs-2 fw-bold'>Related Products</span>
                </div>
                <div className='row'>
                    <div className='product-frame ms-5 shadow rounded' style={{ width: '285px', height: '427px' }}>
                        <div className='heart-icon position-relative ' style={{ left: '94%', top: '5%', fontSize: '18px', lineHeight: '2.5' }}> <i class="bi bi-eye text-secondary"></i><br /><i class="bi bi-suit-heart text-secondary"></i></div>
                        <img className='position-relative' style={{ left: '12%', top: '-17%' }} src={server1014} width='200' height='200' alt="" />
                        <div className='' style={{ marginTop: '2rem' }}>
                            <h5 style={{ fontFamily: 'sans-serif', fontSize: '14px', color: "#373f50", fontWeight: '600' }}>Dell PowerEdge R720 Server</h5>
                            <div className="starRating">
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIconEmpty">★</span>
                            </div>
                            <div className='d-flex'><div className='text-primary fw-bold my-2 inline-block' style={{ width: '250px', fontSize: '18px' }}>&#8377; 15,00,000</div>
                                <div className='inline-block  product-add-icon fw-normal'>
                                    <button className='btn btn-primary py-1 fs-6'>+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='product-frame mx-3 shadow rounded' style={{ width: '285px', height: '427px' }}>
                        <div className='heart-icon position-relative ' style={{ left: '94%', top: '5%', fontSize: '18px', lineHeight: '2.5' }}> <i class="bi bi-eye text-secondary"></i><br /><i class="bi bi-suit-heart text-secondary"></i></div>
                        <img className='position-relative' style={{ left: '12%', top: '-17%' }} src={server1014} width='200' height='200' alt="" />
                        <div className='' style={{ marginTop: '2rem' }}>
                            <h5 style={{ fontFamily: 'sans-serif', fontSize: '14px', color: "#373f50", fontWeight: '600' }}>Dell PowerEdge R720 Server</h5>
                            <div className="starRating">
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIconEmpty">★</span>
                            </div>
                            <div className='d-flex'><div className='text-primary fw-bold my-2 inline-block' style={{ width: '250px', fontSize: '18px' }}>&#8377; 15,00,000</div>
                                <div className='inline-block  product-add-icon fw-normal'>
                                    <button className='btn btn-primary py-1 fs-6'>+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='product-frame me-3 shadow rounded' style={{ width: '285px', height: '427px' }}>
                        <div className='heart-icon position-relative ' style={{ left: '94%', top: '5%', fontSize: '18px', lineHeight: '2.5' }}> <i class="bi bi-eye text-secondary"></i><br /><i class="bi bi-suit-heart text-secondary"></i></div>
                        <img className='position-relative' style={{ left: '12%', top: '-17%' }} src={server1014} width='200' height='200' alt="" />
                        <div className='' style={{ marginTop: '2rem' }}>
                            <h5 style={{ fontFamily: 'sans-serif', fontSize: '14px', color: "#373f50", fontWeight: '600' }}>Dell PowerEdge R720 Server</h5>
                            <div className="starRating">
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIconEmpty">★</span>
                            </div>
                            <div className='d-flex'><div className='text-primary fw-bold my-2 inline-block' style={{ width: '250px', fontSize: '18px' }}>&#8377; 15,00,000</div>
                                <div className='inline-block  product-add-icon fw-normal'>
                                    <button className='btn btn-primary py-1 fs-6'>+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='product-frame me-5 shadow rounded' style={{ width: '285px', height: '427px' }}>
                        <div className='heart-icon position-relative ' style={{ left: '94%', top: '5%', fontSize: '18px', lineHeight: '2.5' }}> <i class="bi bi-eye text-secondary"></i><br /><i class="bi bi-suit-heart text-secondary"></i></div>
                        <img className='position-relative' style={{ left: '12%', top: '-17%' }} src={server1014} width='200' height='200' alt="" />
                        <div className='' style={{ marginTop: '2rem' }}>
                            <h5 style={{ fontFamily: 'sans-serif', fontSize: '14px', color: "#373f50", fontWeight: '600' }}>Dell PowerEdge R720 Server</h5>
                            <div className="starRating">
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIcon">★</span>
                                <span className="starIconEmpty">★</span>
                            </div>
                            <div className='d-flex'><div className='text-primary fw-bold my-2 inline-block' style={{ width: '250px', fontSize: '18px' }}>&#8377; 15,00,000</div>
                                <div className='inline-block  product-add-icon fw-normal'>
                                    <button className='btn btn-primary py-1 fs-6'>+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

