import React from 'react'

export default function TermsandCondition() {
  return (
    <div className='privacy-policy'>
      <p className='head'>GENERAL DESCRIPTION</p>
      <p className='content'>
      JD Technical Solution is the website's owner and operator. The terms "we," "us," and "our" relate to JD Technical Solution throughout the website. You, as the user, are granted access to this website by JD Technical Solution, subject to your compliance with all applicable laws and the terms, conditions, policies, and notices contained herein <br /> <br />
      You engage in our "Services" and agree to be bound by the terms and conditions mentioned below ( "Terms of Service," or "Terms"), as well as any other terms and conditions and policies included in this document or that may be accessed via a hyperlink. All users of this Site, including without limitation browsers, vendors, customers, merchants, and/or content producers, are subject to these Terms and Conditions. <br /> <br />
      Before using or visiting our website, please take the time to thoroughly read these Terms and conditions laid below. You agree to be bounded by these Terms of Service by accessing or using any available element of the website. You are not permitted to use any services or visit the website if you do not accept all of the terms and conditions of this agreement. <br /> <br />
      </p>
      <p className='head2'>SECTION 1 - ONLINE STORE TERMS</p>
      <p className='content'>
      You signify that you are at least the legal age of majority in your state or province of residence by agreeing to these Terms of Service, or that you are the legal age of majority in your state or province of residence and you have granted us permission to permit any of your minor dependents to use this site. You may not use our products for any unlawful or unauthorized purposes, and you may not break any local laws (including, without limitation, copyright laws) while using the Service. Any worms, viruses, or destructive code must not be sent. Your Services will be immediately terminated if any of the Terms are broken or violated.
      </p>
      <p className='head2'>SECTION 2 - MODIFICATIONS TO THE SERVICE AND PRICES</p>
      <p className='content'>
      Without prior notice, our product prices can vary. We retain the right to change or delete providing the Service (or any feature or material within) at any time and without prior notice. Any time the Service is altered, its price is raised, it is suspended, or it is discontinued, we won't be held responsible to you or any other person.
      </p>
      <p className='head2'>SECTION 3 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</p>
      <p className='content'>
      Less accurate, incomplete, data on this website is not our responsibility. The data on this website is provided for informational purposes only and should not be relied upon or used as the only basis for choices without consulting primary, more reliable source, complete, or up-to-date sources of information. Your use of the information on this website is at your own risk, with no interference from us. <br /> <br />
      There may be historical data on this website. Information from the past, which is just offered for your reference and is not always current or up-to-date. Although we reserve the right to change the information on this site at any time, however, we are not obligated to do so.
    </p>
    <p className='head2'>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</p>
    <p className='content'>
    Without prior notice, our product prices can vary. We retain the right to change or hold the Service (or any feature or material within) at any time and without prior notice. Any time the Service is altered, its price is raised, it is suspended, or it is discontinued, we won't be held responsible to you or any other person.
    </p>
    <p className='head2'>SECTION 5 - THIRD-PARTY LINKS</p>
    <p className='content'>
    Materials from third parties may as well included in some content, goods, and services made available through our site. <br /><br />
    You might be taken to third-party websites through links on our website that are not connected to us on our page. We put a disclaimer for all liability and responsibility for any errors or omissions in the content or accuracy of any third-party materials or websites, as well as for any other third-party materials, goods, and services. <br /> <br />
    Any loss or damages resulting from the owning or use of products, services, resources, content, or any other transactions made in relation to any third-party websites are not our responsibility. Before you enter into any transaction, please carefully read and be aware of of the third party's rules and practices to ensure that you are familiar with them. <br /> <br />
    Complaints, claims, worries, or inquiries regarding products from third parties should be addressed to the third party.
    </p>
    <p className='head2'>SECTION 6 - USER COMMENTS, FEEDBACK, AND OTHER COMMUNICATIONS</p>
    <p className='content'>
    You understand and agree that we may, at any time and without restriction, edit, copy, publish, distribute, translate, and otherwise use in any medium any comments that you forward to us, whether in response to one of our specific requests (for example, contest entries) or without our specific request that you forward certain specific submissions (for example, creative ideas, suggestions, proposals, plans, or other materials), and whether online, by email, by postal mail, or otherwise (collectively, "comments"). We are not and will not be obligated to these mentioned below <br /> <br />
    <span className='bg-warning'>(1) maintain the privacy of any comments; (2) give consideration for any remarks, or (3) reply to any comments.</span><br /><br />
    We may, but are under no obligation to, monitor, remove, or block access to any content that violates these terms of service or is, in our sole judgment, illegal, offensive, threatening, defamatory, libelous, pornographic, obscene, or otherwise objectionable content will be removed. <br /><br />
    You acknowledge that your comments won't infringe on anybody else's copyright, trademark, privacy, or other property or personal rights. You further agree that your comments will not contain any defamatory, illegal, abusive, or otherwise objectionable material, nor will they contain any malware or computer viruses that could in any way impair the functionality of the service provided on the website. You may not impersonate another person, use a false email address, or otherwise mislead us or another party as to the source of any remarks. You alone are accountable for the truthfulness of any remarks or comments you make. We disclaim all duty and obligation for any remarks made by other parties or you.

    </p>
    <p className='head2'>SECTION 7 - PERSONAL INFORMATION</p>
    <p className='content'>
    Our Privacy Policy applies to any personal information you submit through the store. View our privacy statement for further information.
    </p>
    <p className='head2'>SECTION 8 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY.</p>
    <p className='content'>
    We make no promises or warranties on the continuity, timeliness, safety-security, or error-free operation of our service. We can not guarantee that the outcomes of using the service will be accurate or trustworthy. You acknowledge that we reserve the right to periodically withdraw the service for all time or to immediately terminate the service without prior notification to you. <br /><br />
    You clearly acknowledge that you use services at your own risk. Except where expressly stated by us. Products and services are not subject to any express or implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, or non-infringement. <br /><br />
    In no occasion will Selective Home Embellishments, our chiefs, officials, workers, members, specialists, project workers, understudies, sellers, specialist co-ops, or licensors be at risk for any injury, misfortune, guarantee, or immediate, roundabout, coincidental, reformatory, exceptional harms or significant harms of any sort, including, however not restricted to, lost benefits, lost income, lost reserve funds, lost information, substitution n costs, numerous comparative harms, whether in agreement, misdeed (counting carelessness), severe responsibility etc., emerging from your utilization of any of the administrations or items bought utilizing the help, or for some other case related in any capacity to your utilization of the assistance or any item, including, without impediment, any mistakes or oversights in any satisfied, or any misfortune or harm of any sort caused because of the utilization of the help or any happy (or item) distributed or, sent, or generally made accessible through the help, regardless of whether you are educated regarding its chance. Since certain states or locales don't permit the prohibition or constraint of obligation for considerable or coincidental harms, in such states or purviews our responsibility will be restricted to the greatest degree allowed by regulation.
    </p>
    <p className='head2'>SECTION 12 - CONTACT INFORMATION</p>
    <p className='content'>
    Questions about the Terms and Conditions should be sent to jdtechnicalsolution@gmail.com
    </p>
    </div>

  )
}
