import React, { useEffect, useState } from 'react';
import { resScript } from './resScript';
import img1 from '../../../img/Banner3.png'
import carousel1 from '../../../img/adwordsRes1.png';
import carousel2 from '../../../img/adwordsRes2.png';
import carousel3 from '../../../img/adwordsRes3.png';
import carousel4 from '../../../img/adwordsRes4.png';


function AdwordsRes() {
    const [magnify, setMagnify] = useState(0);
    // useEffect(() => { resScript() }, [])
    return (
        <>
            <div className='container-fluid result pt-5 pb-5 bg-primary text-center' style={{ marginTop: '0rem', backgroundColor: '' }}>
                <span className='fw-bolder ' style={{ fontSize: '2.5rem', color: 'white' }}>Google Adwords Results <br /></span>
                <div className='fw-bolder position-relative ' style={{ fontSize: '4.9rem', color: 'white', marginTop: '-2%' }}>WITH 6X+ ROAS+</div>
                <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="100">
                            <img src={carousel1} className="d-block w-1000" alt="..."/>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={carousel2} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={carousel3} className="d-block w-100" data-bs-interval="2000" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={carousel4} className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>
    );
}

export default AdwordsRes;
