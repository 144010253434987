import React, { useReducer } from 'react';
import product1 from './Images/product -1.jpg'
import product2 from './Images/product -2.jpg'
import product3 from './Images/product -3.jpg'
import product4 from './Images/product -4.jpg'
import product5 from './Images/product -5.jpg'
import product6 from './Images/product -6.jpg'
import product7 from './Images/product -7.jpg'
import product8 from './Images/product -8.jpg'
import product9 from './Images/product -9.jpg'
import product10 from './Images/product -10.jpg'
import product11 from './Images/product -11.jpg'
import product12 from './Images/product -12.jpg'
console.clear();

const slides = [
  {
    title: "",
    subtitle: "",
    description: "",
    image:`${product1}`
    
  },
  {
    title: "",
    subtitle: "",
    description:"" ,
    image:`${product2}`
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:`${product3}`
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product4}`
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product5}`  
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product6}`  
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product7}`  
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product8}`  
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product9}`  
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product10}`  
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product11}`  
  },
  {
    title: "",
    subtitle: "",
    description: "",
    image:
    `${product12}`  
  }

];

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1
    };
  }
};

function Slide({ slide, offset }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${slide.image}')`
        }}
      />
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${slide.image}')`
        }}
      >
        <div className="slideContentInner">
          <h2 className="slideTitle">{slide.title}</h2>
          <h3 className="slideSubtitle">{slide.subtitle}</h3>
          <p className="slideDescription">{slide.description}</p>
        </div>
      </div>
    </div>
  );
}

export default function ProductDesign() {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);

  return (
    <>
    <div className='' style={{background:'trasparent'}} >
    <div className="slides" style={{overflowX:'hidden', background:''}}>
      <button className='slide-btn' onClick={() => dispatch({ type: "PREV" })} >‹</button>

      {[...slides, ...slides, ...slides].map((slide, i) => {
        let offset = slides.length + (state.slideIndex - i);
        return <Slide slide={slide} offset={offset} key={i} />;
      })}
      <button className='slide-btn ' onClick={() => dispatch({ type: "NEXT" })} >›</button>
    </div>
    </div>
       </>
  );
}
