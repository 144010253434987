import { useState } from "react";
import LogContext from "./LogContext";
import React from 'react'

const LogState=(props)=>{
    const [currencyRatio, setCurrencyRatio]= useState();
    const [currencyType, setCurrencyType]= useState();

    const updateCurrency=(type, ratio)=>{
      setCurrencyRatio(ratio);
      setCurrencyType(type)
    }
  return (
    <LogContext.Provider value={{updateCurrency, currencyRatio, currencyType}}>
            {props.children}
    </LogContext.Provider>
  )
}
export default LogState;