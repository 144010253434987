import React, { useEffect, useRef, useState } from 'react';
import introImg from '../../../img/softwareDevelopment1.jpg'
import img1 from '../../../img/softwareDevelopment2.webp'
import img2 from '../../../img/softwareDevelopment3.webp'
import img3 from '../../../img/softwareDevelopment4.jpg'
import img4 from '../../../img/softwareDevelopment5.jpeg'
import img5 from '../../../img/softwareDevelopment6.webp'
import logo1 from '../../../img/SDLogo1.png';
import logo2 from '../../../img/SDLogo2.png';
import logo3 from '../../../img/SDLogo3.png';
import logo4 from '../../../img/SDLogo4.png';
import logo5 from '../../../img/SDlogo5.png';
import logo6 from '../../../img/SDlogo6.png';
import logo7 from '../../../img/SDlogo7.png';

export default function SoftwareDevelopment() {
    const myElementRef = useRef(null);
    const [enter, setEnter] = useState(0)
    const [stat1, setStat1] = useState(810);
    const [stat2, setStat2] = useState(36);
    const [stat3, setStat3] = useState(0);
    const [stat4, setStat4] = useState(1800);
    const [stat5, setStat5] = useState(21);
    var i = 0; var j = 0; var k = 0; var l = 0; var m = 0;
    const handleScroll = () => {
        console.log('scroll')
        const intervalID = setInterval(() => {
            if ((window.scrollY > 6000) && enter === 0) {
                if (i < 122) { i = i + 1; setStat1(i) }
                if (j < 92) { j = j + 1; setStat2(j) }
                if (k < 183) { k = k + 1; setStat3(k) }
                if (l < 12721) { l += 100; setStat4(l) }
                if (l > 12721) { l = l - 1; setStat4(l) }
                if (m < 17) { m = m + 1; setStat5(m) }
            }
            else {
                clearInterval(intervalID);
                // setStat1(0);setStat2(0);setStat3(0);setStat4(0);setStat5(0);
                // Stop the interval when stat1 reaches 122
            }
        });
        setEnter(1)
    }
    useEffect(() => {
        if (myElementRef.current) {
            myElementRef.current.addEventListener('mouseenter', handleScroll);
        }
    })
    return (
        <div>
            <div className='software-development'>
                <div className='intro container-fluid row bg-light'>
                    <div className='col-6 ps-md-5 p-1 info'>
                        <p className='head'>
                            Empowering Your Business with Innovative Solutions
                        </p>
                        <p className='content'>
                            At JD Technical Solution, we specialize in delivering cutting-edge software solutions tailored to meet your unique business needs. With a focus on innovation, quality, and client satisfaction, we are your trusted partner in harnessing the power of technology to drive your success.
                        </p>
                    </div>
                    <div className='col-6 image'>
                        <img src={introImg} alt="" />
                    </div>
                </div>
                <div id='stats' ref={myElementRef}>
                    <div className=' get-demo container custom-cursor' style={{ marginTop: '9rem' }}>
                        <div id='stats' className='container mx-md-5 mx-1 fs-3 fw-bold text-white' style={{ overflowY: 'scroll' }}>OUR ACHIEVEMENTS</div>
                        <div className='row stats-row' >
                            <div className='col-md-4 col-2'></div>
                            <div className='col-md-4 col-5'>
                                <span className='stats-head fw-bolder'>{stat1}+</span>
                                <p className='stats-content position-relative fw-md-normal fw-bold text-secondary'>Global Costumers<br /><br /></p>
                                <hr className='stats-col1 position-relative' />
                            </div>
                            <div className='col-md-4 col-5'>
                                <span className='stats-head fw-bolder text-primary'>{stat2}+</span>
                                <p className='stats-content fw-md-normal fw-bold text-secondary'>Ountries Client Served <br /><br /></p>
                                <hr />
                            </div>
                            {/* <div className='col-md-4 col-2'></div> */}
                            <div className='col-md-4 col-4'>
                                {/* <span className='revenue text-warning' style={{ fontWeight: '200px' }}>${stat3}M</span>
                                <p className='stats-content position-relative fw-md-normal text-secondary fw-bold'>Copmleted Projects<br /><br /></p>
                                <hr className='stats-col3 position-relative' /> */}
                            </div>
                            <div className='col-md-4 col-4'>
                                <span className='stats-head text-warning fw-light'>{stat4}+</span>
                                <p className='stats-content fw-md-normal fw-bold text-secondary position-relative'>Copmleted Projects<br /><br /></p>
                                <hr className='stats-col4 position-relative' />
                            </div>
                            {/* <div className='col-md-4 col-4'></div> */}
                            <div className='col-md-4 col-4'>
                                <span className='stats-head fw-bolder'>{stat5}+</span>
                                <p className='stats-content fw-md-bold fw-bolder position-relative' style={{ color: 'rgb(63, 63, 63)' }}>Years of Expreience<br /><br /></p>
                                <hr className='stats-col5 position-relative' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='innovation container-fluid my-5'>
                    <p className=' head fw-bold text-center'>
                        Innovation is Right here for You
                    </p>
                    <p className='text-center contentt'>Applying Design Thinking and Practicla Creativeity, we make Innovative Business Software Solutions.</p>
                    <hr />
                    <div className='row container-fluid ms-md-1 p-md-2 p-0 ms-0' >
                        <div className='col-6 p-md-2 p-0'>
                            <div className='google-adwords-process'>
                                <p className='head'>Agile Based Decision Making</p>
                                <p className='content'>
                                    With our value driven approach in developing custom software, we strategize our complete process and deliver high quality wark. By keeping a check on everything including budget, time, resources, technology and security, our software developers ensure well tested and measured steps, deriving productive end result in utilizing Agile methodology.                            </p>
                            </div>
                        </div>
                        <div className='col-6 p-0 shadow'>
                            <img src={img1} className="google-adwords-img" alt="" />
                        </div>


                        <div className='col-6 p-0 shadow'>
                            <img src={img2} className='google-adwords-img' alt="" />
                        </div>
                        <div className='col-6'>
                            <div className='google-adwords-process'>
                                <p className='head'>User-Oriented Approach</p>
                                <p className='content'>
                                    Our implementation is focused on tailoring the bespoke software solution for your specific needs, promoting cooperation and reducing conflicts. Acquiring the complete knowledge of customer requirements, our software developers are skilled enough to create a better end-user experience with the help of software development tools.</p>
                            </div>
                        </div>


                        <div className='col-6'>
                            <div className='google-adwords-process'>
                                <p className='head'>Cloud & Devops</p>
                                <p className='content'>
                                    The digital transformation for your business processes is easily achieved with our cloud native and DevOps focused Approach. We, as a software development company, aim to streamline the , automation processes and achieve business agility, delivering quality services with total commitmeht and become your trusted software development partner.</p>
                            </div>
                        </div>
                        <div className='col-6 p-0 shadow '>
                            <img src={img3} className='google-adwords-img' alt="" />
                        </div>


                        <div className='col-6 p-0 shadow'>
                            <img src={img4} className="google-adwords-img" alt="" />
                        </div>
                        <div className='col-6'>
                            <div className='google-adwords-process'>
                                <p className='head'>Team Agumentation</p>
                                <p p className='content'>
                                    Our flexible business models enable clients to utilize on-demand skills of our software engineers. The expert talent pool specializes to serve global software outsourcing needs across various technologies to enhance your business growth and meet the business objectives with short and long term engagements, cost effectively.                        </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' whyChooseUs row container-fluid p-0 mx-0' style={{ backgroundColor: 'rgb(253,224,44)' }}>
                    <div className='col-md-7 info'>
                        <p className='head ms-md-5 ms-1 mt-3'>Why Choose US ?</p>
                        <div className='content ms-md-5 my-5'>
                            <div className='d-flex mt-5'>
                                <div><img src={logo1} alt="" /></div>
                                <div className='ms-3'><span className='logo-head'>
                                    On Demand. Flexible</span><br />
                                    <span>Choose the best fit remote-team as your business demands</span>
                                </div>
                            </div>
                            <div className='d-flex mt-3'>
                                <div><img src={logo2} alt="" /></div>
                                <div className='ms-3'><span className='logo-head'>
                                    Cost Benifit</span><br />
                                    <span>Drive up to 30% business savings with our competitive offerings</span>
                                </div>
                            </div>
                            <div className='d-flex mt-3'>
                                <div><img src={logo3} alt="" /></div>
                                <div className='ms-3'><span className='logo-head'>
                                    Abundent IT Experts</span><br />
                                    <span>Scale up engineers skilled across various technologies</span>
                                </div>
                            </div>
                            <div className='d-flex mt-3'>
                                <div><img src={logo4} alt="" /></div>
                                <div className='ms-3'><span className='logo-head'>
                                    Eliminate Risks</span><br />
                                    <span>Achieve success with agile project development and consistent delivery</span>
                                </div>
                            </div>
                            <div className='d-flex mt-3'>
                                <div><img src={logo5} alt="" /></div>
                                <div className='ms-3'><span className='logo-head'>
                                    Quality Assurence</span><br />
                                    <span>Quality is at the core of everything we do.</span>
                                </div>
                            </div>
                            <div className='d-flex mt-3'>
                                <div><img src={logo6} alt="" /></div>
                                <div className='ms-3'><span className='logo-head'>
                                    Colloborative Approach</span><br />
                                    <span>We understand your requirements, provide regular updates, and incorporate your feedback throughout the development process.</span>
                                </div>
                            </div>
                            <div className='d-flex mt-3'>
                                <div><img src={logo7} alt="" /></div>
                                <div className='ms-3'><span className='logo-head'>
                                    Timely Delivery</span><br />
                                    <span>We understand the importance of deadlines. With our efficient project management practices, we ensure timely delivery without compromising on quality</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {window.innerWidth>576 &&
                        <div className='col-5 image'>
                            <img src={img5} className='position-relative' alt="" />
                        </div>
                    }
                </div>
                <div className="accordion accordion-flush p-md-5 p-2" id="accordionFlushExample">
                    <p className='mx-3 fs-2 fw-bold'> FAQs</p>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                               As a Software Development firm, What Software Services do you offer?
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                We provide full-scale software development services like custom software development, web app development, mobile app development, software testing, and similar services. Our robust software development process ensures that everything within our software development services is done in a clean manner to provide top-notch services.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Which technologies you use to develop Software and web application?
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                We offer a variety of web and software development services that are inclusive of a range of technologies, diversified frameworks, and programming languages. However, the selection of technology stack is completely dependent on the client for their project as to what brings the best of business performance for them. Although, we suggest and assist businesses by analyzing their ausiness requirements, budget, future demands and available technology stack.                                
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                               What different hiring models do you offer for Software Development?
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                            <span className='fs-4'>Fixed Price Model</span><br /><br />
                                In this model, the project scope, timeline, and cost are defined upfront.
                                It's suitable for well-defined projects with clear requirements.
                                Clients pay a fixed price for the entire project, regardless of the actual time and effort spent.
                                <br /><br />
                               <span className='fs-4'>Outcome-based Model</span><br /><br />
                                Payments are tied to the achievement of specific outcomes or results.
                                Clients pay based on the value delivered rather than the time or effort invested.
                                Requires clearly defined metrics and objectives upfront.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
