import React, { useEffect, useRef, useState } from 'react'
export default function Stats() {
    const myElementRef = useRef(null);
    const [enter,setEnter]=useState(0)
    const[stat1,setStat1]=useState(0);
    const[stat2,setStat2]=useState(0);
    const[stat3,setStat3]=useState(0);
    const[stat4,setStat4]=useState(0);
    const[stat5,setStat5]=useState(0);
    var i=0; var j=0; var k=0; var l=0; var m=0;
    const handleScroll=()=>{
        console.log('scroll')
        const intervalID = setInterval(() => {
            if ((window.scrollY>6000) && enter===0) {               
                if(i<122){i=i+1;setStat1(i)}
                if(j<92){j=j+1;setStat2(j)}
                if(k<183){k=k+1;setStat3(k)}
                if(l<12721){l+=100;setStat4(l)}
                if(l>12721){l=l-1;setStat4(l)}
                if(m<17){m=m+1;setStat5(m)}
            }
             else 
             {
                clearInterval(intervalID);
                // setStat1(0);setStat2(0);setStat3(0);setStat4(0);setStat5(0);
                 // Stop the interval when stat1 reaches 122
            }
        });
        setEnter(1)
     }
     useEffect(()=>{
        if (myElementRef.current) {
            myElementRef.current.addEventListener('mouseenter',handleScroll);
          }
     })
    
  return (

    <>
    <div id='stats' ref={myElementRef}>
      <div className='container custom-cursor' style={{ marginTop: '9rem' }}>
                <div id='stats' className='container mx-md-5 mx-1 fs-md-3 fs-7 fw-bold text-secondary'style={{overflowY: 'scroll'}}>COMPANY STATS</div>
                <div className='row stats-row' >
                    <div className='col-md-4 col-2'></div>
                    <div className='col-md-4 col-5'>
                        <span className='stats-head fw-bolder'>{stat1}</span>
                        <p className='stats-content position-relative fw-md-normal fw-bold text-secondary' >Delevering Ads Globally <br /><br /></p>
                        <hr className='stats-col1 position-relative' />
                    </div>
                    <div className='col-md-4 col-5'>
                        <span className='stats-head fw-bolder text-primary'>{stat2}%</span>
                        <p className='stats-content fw-md-normal fw-bold text-secondary'>Of Clients Have Doubled Their Revenue In Their First Year With JDTS</p>
                        <hr />
                    </div>
                    {/* <div className='col-md-4 col-2'></div> */}
                    <div className='col-md-4 col-4'>
                        <span className='revenue text-warning' style={{fontWeight:'200px'}}>${stat3}M</span>
                        <p className='stats-content position-relative fw-md-normal text-secondary fw-bold'>Revenue Generated <br /><br /></p>
                        <hr className='stats-col3 position-relative'/>
                    </div>
                    <div className='col-md-4 col-4'>
                        <span className='stats-head fw-bolder '>{stat4}</span>
                        <p className='stats-content fw-md-normal fw-bold text-secondary position-relative'>High Volume Creatives Produced A Month<br /><br /></p>
                        <hr className='stats-col4 position-relative'/>
                    </div>
                    {/* <div className='col-md-4 col-4'></div> */}
                    <div className='col-md-4 col-4'>
                        <span className='stats-head fw-bolder'>{stat5}</span>
                        <p className='stats-content fw-md-bold fw-bolder position-relative' style={{ color: 'rgb(63, 63, 63)' }}>Startup Investments <br /><br /></p>
                        <hr className='stats-col5 position-relative'/>
                    </div>
                </div>
            </div>
            </div>
    </>
  )
}
