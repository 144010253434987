import React from 'react';
import Marquee from "react-fast-marquee";
import testimonial1 from '../img/testimonial-1.jpg';
import testimonial2 from '../img/testimonial-2.jpg';
import testimonial3 from '../img/testimonial-3.jpg';
import testimonial4 from '../img/testimonial-4.jpg';
import { useLocation } from 'react-router-dom';

const Testimonial = () => {
  const location= useLocation();
  return (
    <>
        <div id="testimonial" className='container-fluid position-relative opacity-0' style={{top:'3rem',height:'10px'}}></div>
    { location.pathname!='/login' &&
    <div>
    <div className="container-fluid testimonial pt-5" style={{ overflow: 'hidden' }}>
      <div className="container">
        <div className="mx-auto text-center mb-5" style={{ maxWidth: '900px' }}>
          <h5 className="section-title px-3">Testimonial</h5>
          <h1 className="mb-0">Our Clients Say!!!</h1>
        </div>
      </div>
    </div>
    <Marquee>
    <div className="container-fluid testimonial pt-1 pb-5" style={{overflow:'hidden'}}>
      <div className="container px-0 pt-3 pb-5">
        <div className=" row testimonial-carousel owl-carousel" style={{Width:'200%'}}>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5">
                JDTS has had not only a huge impact on my business,but also a big impact in my life. Everything started to take off when I started to work with JDTS.
                These genuis gyus started to take further and further and we went $2000 to close to $800000 per month in January 2020!
                 <br />
                 </p>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial1} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">Florian F.</h5>
              <p className="mb-0">CEO, Ketorecipe</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5">
                What I appericiate most at JDTS are the results.We started in October and the results exceed our expectatios!
                <span className='text-white'><br /><br /><br /><br /><br />.</span>
                </p>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial2} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">Alan</h5>
              <p className="mb-0">CEO, Brilliant Pad</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5">I love the fact that my account manager at JDTS trea's my account like their own. JDTS has a high consideration for me and really cares about my business. I love how details oriented JTDS is. I love the consistency in my results and I have seen growth.</p>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial3} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">Wayne</h5>
              <p className="mb-0">Founder, Trinity Hills</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
          <div className="testimonial-item text-center rounded pb-4 col-3">
            <div className="testimonial-comment bg-light rounded p-4">
              <p className="text-center testimonial-content mb-5" style={{fontSize:'13px'}}>I spend a $3K on video shoot and we got back the first day we started running ads from those creatives. <br /><br /><br /><br /><br /></p>
              <span className='text-white'>.</span>
            </div>
            <div className="testimonial-img p-1">
              <img src={testimonial4} className="img-fluid rounded-circle" alt="Image" />
            </div>
            <div style={{ marginTop: '-35px' }}>
              <h5 className="mb-0">Dave</h5>
              <p className="mb-0">CEO, Paw.com</p>
              <div className="d-flex justify-content-center">
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
                <i className="fas fa-star text-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Marquee>
    </div>
    }
    </>
  );
};

export default Testimonial;
