import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { homeScroll } from '../../../smoothScroll';
import PhotosDevelopment from './PhotosDevelopment';
import LogContext from '../../../context/LogContext';
export default function VideosDevelopment() {
  const myContext = useContext(LogContext);
    const [currencyRatio, setConverterRatio] = useState();
    const [currencyType, setCurrencyType] = useState();
    useEffect(() => {
        setConverterRatio(parseFloat(myContext.currencyRatio));
        setCurrencyType(myContext.currencyType);
    }, [myContext]);
  return (
    <>
      <div className="custom-shape-divider-bottom-1714370669">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
      </div>
      <div className='video-production p-0 ' style={{ backgroundColor: 'rgb(15,195,255,.8)' }}>
        <div className='container-fluid row about'>
          <div className='col-md-6'>
            <p className='head position-relative text-white '>Create <br />to Attract</p>
          </div>
          <div className='col-md-6'>
            <p className=' content fs-5'>We’re a full-service video production company that aligns your business goals with our creative vision and technical finesse, transforming imagination to execution. Whether you are an emerging start-up or an established brand, we are here to tell your story and boost your engagement with the power of videos.
              Leveraging our vast talent pool of strategists, scriptwriters, cinematographers, editors, social media experts, web geeks and production team that can pull off every kind of film with effortless ease. To cut a long story short, think of us as your extended marketing team, your own video storytellers.
            </p>
          </div>
        </div>
        <div className='container-fluid my-5 row our-services'>
          <div className='col-md-4 col-12 mb-5'>
            <div className='cards text-center'>
              <img className="img1 position-relative" src='https://dreamfoot.in/wp-content/uploads/2022/12/undraw_in_real_life_v8fk-1.png' alt="" />
              <p className='card-head1'>Promotional Video</p>
            </div>
          </div>
          <div className='col-md-4 col-12 mb-5'>
            <div className='cards text-center'>
              <img className='img2 position-relative' src="https://dreamfoot.in/wp-content/uploads/2022/12/undraw_Powerful_re_frhr-300x205.png" alt="" />
              <p className='card-head1'>Social Impact Video</p>
            </div>
          </div>
          <div className='col-md-4 col-12 mb-5'>
            <div className='cards text-center'>
              <img className='img3 position-relative' src="https://dreamfoot.in/wp-content/uploads/2022/12/undraw_coffee_break_h3uu.png" alt="" />
              <p className='card-head3'>Saftey Animation</p>
            </div>
          </div>
          <div className='col-md-4 col-12 mt-5 position-relative' style={{ left: '15%' }}>
            <div className='cards text-center'>
              <img className="img position-relative" src="https://dreamfoot.in/wp-content/uploads/2022/12/undraw_Having_fun_re_vj4h.png" alt="" />
              <p className='card-head'>Product Animation</p>
            </div>
          </div>
          <div className='col-md-4 col-12 mt-5 position-relative' style={{ left: '15%' }}>
            <div className='cards text-center'>
              <img className='img position-relative' src="https://dreamfoot.in/wp-content/uploads/2022/12/Group-54.png" alt="" />
              <p className='card-head'>Explainer Video</p>
            </div>
          </div>
        </div>
        <div className='container my-5 worth-the-pay'>
          <p className='head text-center'>We craft strategies that turn your goal into memorable videos</p>
          <p className='content text-justify'> Video production services can easily pay for themselves over and over again since you can use a single video in several different ways. You can use the same video on your website, in your email marketing, on social media, and just about anywhere else. Using your video in multiple places across your marketing efforts maximizes your investment in video production services and increases your ROI. Using the information and tips in this article, you have everything you need to create a ridiculously strong video marketing strategy, regardless of whether you bring your video production services in-house or outsource to an established video production company.
            Your brand has a unique story to tell that sets you apart from your competition and builds powerful brand identity. Video is one of the best ways for your brand to share that story while also keeping visitors on your website longer, keeping their attention, and getting them to engage. High-quality video and a strong video marketing strategy can bring in new customers and keep existing customers coming back for more.
          </p>
        </div>
        <PhotosDevelopment/>
        <div className='why-us container'>
          <p className='head text-center'>Why us?</p>
          <div className='content row my-5'>
            <div className=' cards col-md-3 col-12 me-md-5'>
              <img className='img1' src="https://dreamfoot.in/wp-content/uploads/2022/12/why-us-10.png" alt="" />
              <p className='head'>Creative & Flexible</p>
              <p className='text text-center'>
                We are creative content producers. We know how to tell compelling stories that connect your brand with your audience. We quickly adapt to the shifting media landscape, so with us as your video production partner, there is no shot too hard, travel too far or idea too grand.
              </p>
            </div>
            <div className='cards col-md-3 col-12 mx-md-5'>
              <img className=' img2' src="https://dreamfoot.in/wp-content/uploads/2022/12/why-vers.png" alt="" />
              <p className='head'>Versatile</p>
              <p className='text text-center'>
                Every video is different. Creating content across a wide range of genres from internal corporate, explainer, product how-to video, to animated safety induction video and marketing video for social media, we have a versatile portfolio and adaptable eye to give the best outcome for each project.
              </p>
            </div>
            <div className=' cards col-md-3 col-12 ms-md-5'>
              <img className='img3' src="https://dreamfoot.in/wp-content/uploads/2022/12/19.png" alt="" />
              <p className='head'>Experienced</p>
              <p className='text text-center'>
                We have 8+ years of experience in crafting more than 400 films for clients across different industries. Our strong execution strength lends us the advantage to spearhead and successfully deliver challenging projects in different genres, with unique operating capacities for businesses of all sizes.
              </p>
            </div>
          </div>
        </div>
        <div className='video-plans'>
          <p className='head'>Video Plans</p>
          <table className='table rounded shadow'>
            <tr className='heading'>
              <td className='shadow '>Type</td>
              <td className='shadow '>Motion Duration</td>
              <td className='shadow '>Pricing</td>
            </tr>
            <tr className='content'>
              <td>Product Video</td>
              <td>1 min</td>
              <td>{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*300).toFixed(2)}</td>
            </tr>
            <tr className='content'>
              <td>Motion Video</td>
              <td>1 min</td>
              <td>{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*5000).toFixed(2)}</td>
            </tr>
            <tr className='content'>
              <td>Animated Video</td>
              <td>1 min</td>
              <td>{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*10000).toFixed(2)}</td>
            </tr>
            <tr className='content'>
              <td>Influncer Video</td>
              <td>1 min</td>
              <td>{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*12000).toFixed(2)}</td>
            </tr>
          </table>
          <p className='head'>Photos Plans</p>
          <table className='table rounded shadow'>
            <tr className='heading'>
              <td className='shadow '>Type</td>
              <td className='shadow '>Number</td>
              <td className='shadow '>Pricing</td>
            </tr>
            <tr className='content'>
              <td>Static Photos</td>
              <td>Single</td>
              <td>{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*350).toFixed(2)}</td>
            </tr>
            <tr className='content'>
              <td>Motion Photos</td>
              <td>Single</td>
              <td>{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*500).toFixed(2)}</td>
            </tr>
            <tr className='content'>
              <td>Animated Photos</td>
              <td>Single</td>
              <td>{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*750).toFixed(2)}</td>
            </tr>
          </table>
        </div>
        
        <div className="accordion accordion-flush p-md-5 p-2" id="accordionFlushExample">
          <p className='mx-3 fs-2 fw-bold FAQ'> FAQs</p>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                As a Software Development firm, What Software Services do you offer?
              </button>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse my-0 collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                We provide full-scale software development services like custom software development, web app development, mobile app development, software testing, and similar services. Our robust software development process ensures that everything within our software development services is done in a clean manner to provide top-notch services.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                Which technologies you use to develop Software and web application?
              </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse  my-0 collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                We offer a variety of web and software development services that are inclusive of a range of technologies, diversified frameworks, and programming languages. However, the selection of technology stack is completely dependent on the client for their project as to what brings the best of business performance for them. Although, we suggest and assist businesses by analyzing their ausiness requirements, budget, future demands and available technology stack.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                What different hiring models do you offer for Software Development?
              </button>
            </h2>
            <div id="flush-collapseThree" className="accordion-collapse my-0 collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <span className='fs-4'>Fixed Price Model</span><br /><br />
                In this model, the project scope, timeline, and cost are defined upfront.
                It's suitable for well-defined projects with clear requirements.
                Clients pay a fixed price for the entire project, regardless of the actual time and effort spent.
                <br /><br />
                <span className='fs-4'>Outcome-based Model</span><br /><br />
                Payments are tied to the achievement of specific outcomes or results.
                Clients pay based on the value delivered rather than the time or effort invested.
                Requires clearly defined metrics and objectives upfront.
              </div>
            </div>
          </div>
        </div>
        <div className='container schedule'>
          <p className='head text-center'> Schedule A Call</p>
          <p className='content text-center'>
            Not a fan of filling a form?<br />
            We can jump on a call to discuss your projects requirements. <br /><br />
            Schedule a free video strategy call with us.
          </p>
          <div onClick={() => { homeScroll('form') }} className='text-center' style={{height:'4rem'}}><Link to="/contact" className='btn btn-primary bubble-btn rounded-pill' style={{ paddingInline: '3rem'}}>Book Now</Link></div>
        </div>
      </div>
      <div class="custom-shape-divider-top-1714371382">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
      </div>
    </>
  )
}
