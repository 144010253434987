import React, { useEffect, useState } from 'react'
import contactUs from '../img/contactUs .png'
import jd_video from '../img/jdtsFinal.mp4';
import { Link, useLocation } from 'react-router-dom';
import { homeScroll } from '../smoothScroll';
import banner1 from '../img/Banner.png';
import banner2 from '../img/Banner2.png';
import banner3 from '../img/Banner3.png';
import adwords1 from '../img/advords.png';
import adwords2 from '../img/googleAdwordsbanner.png'
import adwords3 from '../img/advords2.png';
import adwords4 from '../img/advards4.png';

import autodial1 from '../img/Auto-Dialer1.jpg'
import adwords5 from '../img/googleAdwards6.jpg'
import logo8 from '../img/Mlogo8.jpg'
import SoftwareDevelopment from '../img/softwareDevelopment.jpg';
import websiteDevelopment from '../img/websiteDevelopment.jpg';
import mediaDevelopment1 from '../img/MD3.png';
import mediaDevelopment2 from '../img/MD2.png';
import adwords6 from '../img/FINAL PAGE.png'
import WD2 from '../img/WDCarousel.jpg';
import seo from '../img/SEO.webp';
import meta from '../img/metaBanner.avif';
import tnc from '../img/tnc.png';
import sla from '../img/sla.png';
import privacypolicy from '../img/privacy policy.png'
export default function Carousel() {
  const location = useLocation();
  const [responsiveHead, setResponsiveHead] = useState('-10%');
  useEffect(() => {
    if (window.innerWidth < 844) {
      setResponsiveHead('10%')
    }
  })
  return (
    <>
      {
        (location.pathname != '/singleProduct' && location.pathname != '/login')
        &&
        <div id='carousel' className="carousel-header" style={{ zIndex: '10' }}>
          <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
            <ol className="carousel-indicators">
              <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
              <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
            </ol>
            <div className="carousel-inner" role="listbox">

              <div className="carousel-item active">
                {location.pathname === '/contact' &&
                  <img src={contactUs} className="contact-img" alt="" />
                }
                {(location.pathname === '/' && window.screen.width > 844) &&
                  <video className='videoFront' height='650' src={jd_video} autoPlay={true} loop={true} muted={true} >
                    Your browser does not support the video tag.
                  </video>
                }
                {(location.pathname === '/' && window.screen.width <= 844) &&
                  <img src={WD2} className="autodial-img" alt="" />
                }
                {location.pathname === '/googleAds' &&
                  <img src={adwords2} className='autodial-img' alt="/" />
                }
                {location.pathname === '/theAutodial' &&
                  <img src={autodial1} className='autodial-img' alt='/'></img>

                }
                {location.pathname === '/softwareDevelopment' &&
                  <img src={SoftwareDevelopment} className='autodial-img' alt='/'></img>

                }
                {location.pathname === '/websiteDevelopment' &&
                  <img src={WD2} className='autodial-img' alt='/'></img>

                }
                {location.pathname === '/graphicDesigning' &&
                  <img src={mediaDevelopment2} className='autodial-img' alt='/'></img>

                }
                {location.pathname === '/seo' &&
                  <img src={seo} className='autodial-img' alt='/'></img>

                }
                {location.pathname === '/metaAds' &&
                  <img src={meta} className='autodial-img' alt='/'></img>

                }
                {location.pathname === '/meta-ads-landingpage' &&
                  <img src={meta} className='autodial-img' alt='/'></img>

                }
                 {location.pathname === '/SLA' &&
                  <img src={sla} className='policy-img' alt='/'></img>

                }
                 {location.pathname === '/t&c' &&
                  <img src={tnc} className='policy-img' alt='/'></img>

                }
                 {location.pathname === '/privacyPolicy' &&
                  <img src={privacypolicy} className='policy-img' alt='/'></img>

                }
                <div className="carousel-caption" style={{}}>
                  {location.pathname === '/' &&
                    <div className="p-3 home-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Automate Today!
                      </p>

                      <p className="mb-md-5 mb-0 text2">
                        JD Technical Solution is a gateway to open a Business with the best Automated and Integrated Technology around you.
                      </p>
                      <div onClick={() => { homeScroll('form') }} className='home-btn'><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Book your Free Consultation Now!</span></Link></div>
                    </div>}

                  {location.pathname === '/googleAds' &&
                    <div className="p-3 ga-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Transform Clicks into customer!
                      </p>

                      <p className="mb-md-5 mb-0 text2">
                        Tailored Google AdWords Solutions for Every Business.With an extensive experience of managing paid ads, we run high impact ads, which can bring quality traffic at a reasonable price.
                      </p>
                      <div onClick={() => { homeScroll('form') }}className='ga-btn' style={{ height: '45px' }}><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Book your Free Consultation Now!</span></Link></div>
                    </div>}
                  {location.pathname === '/seo' &&
                    <div className="p-3 seo-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        It's not about 'getting the traffic - but it's about "getting the targeted and relevant traffic.
                      </p>

                      <p className="mb-md-5 mb-0 text2">
                      </p>
                      <div onClick={() => { homeScroll('form') }} style={{ height: '45px' }}><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Book your Free Consultation Now!</span></Link></div>
                    </div>}
                  {location.pathname === '/metaAds' &&
                    <div className="p-3 seo-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Boost Your Web Traffic, Conversions & Sales                      </p>

                      <p className="mb-md-5 mb-0 text2">
                      </p>
                      <div onClick={() => { homeScroll('form') }} style={{ height: '45px' }}><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Book your Free Consultation Now!</span></Link></div>
                    </div>}
                    {location.pathname === '/meta-ads-landingpage' &&
                    <div className="p-3 seo-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Boost Your Web Traffic, Conversions & Sales                      </p>

                      <p className="mb-md-5 mb-0 text2">
                        <a href="tel: +1-888-821-5749" id="USContactLink" style={{display:"none"}}></a>
                      </p>
                      <div onClick={() => { homeScroll('form') }} style={{ height: '45px' }}><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Book your Free Consultation Now!</span></Link></div>
                    </div>}
                  {location.pathname === '/theAutodial' &&
                    <div className="p-3 ad-head" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Calling made easy with automated progressive dialing
                      </p>

                      <p className="mb-md-5 mb-0 text2">
                        Get rid of the complications of dialing each number manually, noting each follow-up’s date and time, rescheduling calls, etc. Enhance your telecalling experience for greater efficiency with NeoDove’s calling solution.                    </p>
                      <div onClick={() => { homeScroll('form') }} className='bubble-div ad-btn'><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Request a Demo!</span></Link></div>
                    </div>}
                  {location.pathname === '/softwareDevelopment' &&
                    <div className="p-3 sd-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white sd-text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Stay ahead in the Race with Innovative Software Solutions!
                      </p>

                      <p className="mb-md-5 mb-0 sd-text2">
                        JD Technical Solution excels in solving complex Software Development challanges using Thought Process and Technology Solutions.
                      </p>
                      <div onClick={() => { homeScroll('form') }} className='bubble-div sd-btn'><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Contact us Today!</span></Link></div>
                    </div>
                  }
                  {location.pathname === '/websiteDevelopment' &&
                    <div className="p-3 wd-head" style={{ maxWidth: "900px" }}>
                      <p className="text-white wd-text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Where Websites Come Alive!
                      </p>

                      <p className="mb-md-5 mb-0 wd-text2">
                        At JDTS we are not building websites;we're crafting digital experiences that express, captivate, engage and convert.
                      </p>
                      <div onClick={() => { homeScroll('form') }} className='bubble-div wd-btn'><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Automate Today !</span></Link></div>
                    </div>

                  }
                  {location.pathname === '/graphicDesigning' &&
                    <div className="p-3 position-relative" style={{ maxWidth: "900px", top: '-10%' }}>
                      <p className="text-white gd-text1 text-uppercase fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Hook Your Audience with Soulfull Meaning Full And Powefull Videos
                      </p>
                      <h1 className="display-2 gd-text-capitalize text-white mb-md-4 mb-0"></h1>
                      <p className="mb-md-5 mb-0 text2">

                      </p>
                      <div onClick={() => { homeScroll('form') }} className='bubble-div gd-btn'><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Schedule A Call !</span></Link></div>
                    </div>

                  }

                  {location.pathname === '/contact' &&
                    <div className="p-3 contact-head position-relative" style={{ maxWidth: "900px" }}>
                      <p className="text-white text1 text-uppercase fs-1 fw-bold mb-md-4 mb-0" style={{ letterSpacing: "3px" }}>
                        Contact Us!
                      </p>

                      <div onClick={() => { homeScroll('form') }} className='contact-btn' style={{height:'4rem'}}><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded-pill text-white py-md-3 py-1 px-md-5 px-2"><span className=''>Book your Free Consultation Now!</span></Link></div>
                    </div>}

                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
              <span className="carousel-control-prev-icon btn" style={{ backgroundColor: "rgb(15, 195, 250)" }} aria-hidden="false"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
              <span className="carousel-control-next-icon btn" style={{ backgroundColor: "rgb(15, 195, 250)" }} aria-hidden="false"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div id="form" className='container-fluid opacity-0 position-absolute' style={{ height: '5px', top: '1205px' }}></div>
          <div id="plan" className='container-fluid opacity-0 bg-dark position-absolute' style={{ height: '8px',top:'4110px' }}></div>
          <div id="about" className='container-fluid opacity-0 bg-dark position-absolute' style={{ height: '8px',top:'78rem' }}></div>

        </div>} 

    </>
  )
}
