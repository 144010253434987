import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div className='privacy-policy'>
      <p className='head'>SECTION 1 - DISCLOSURE</p>
      <p className='content'>We may disclose your personal information if we are required to do so by law or if you violate our Terms of Service.</p>
      <p className='head2'>Payments</p>
      <p className='content'>
      If you choose a direct payment gateway to complete your purchase, then JD Technical Solution stores your credit card data. It is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only as long as it is necessary to complete the purchase transaction. After that is complete, your purchase transaction information is deleted. <br />
      All direct payment gateways adhere to the standards set by PCI-DSS as outlined by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express, and Discover. <br />      
      PCI-DSS requirements help ensure the secure handling of credit card information by stores and their service providers. For more insight, you may also want to read JD Technical solution Terms of Service here or Privacy Statement here.
      </p>
      <p className='head2'>SECTION 2 - THIRD PARTY SERVICES</p>
      <p className='content'>
      In general, the third-party services used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, some third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies regarding the information we are required to provide to them for purchase-related transactions. For these providers, we recommend that you read their privacy policies so that you can understand how your personal information will be handled. <br />
      In general, the third-party services used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, some third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies regarding the information we are required to provide to them for purchase-related transactions. For these providers, we recommend that you read their privacy policies so that you can understand how your personal information will be handled. <br />
      Once you leave our store website or are redirected to a third party site or application, you are no longer governed by this Privacy Policy or our website Terms of Service. <br />
      </p>
      <p className='head2'>Links:</p>
      <p content='content'>
      When you click on links in our store, you may be redirected away from our site. We are not responsible for the privacy practices of other sites and we encourage you to read their privacy statements.
      </p>
      <p className='head2'>SECTION 3 - SECURITY</p>
      <p className='content'>
      To protect your personal information, we take reasonable precautions and follow industry best practices to ensure that it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed. <br /><br />
      DSS and implement additional industry accepted standards.
      </p>
    </div>
  )
}
