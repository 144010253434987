import React, { useEffect, useState } from 'react';
import {back} from './back.js';
import img from '../img/Banner3.png';
function Test() {
    const [magnify,setMagnify] = useState(0);
    return (
        <div className="container">
            {/* <h1>Image Magnifier</h1>
            <p>Move your mouse over the image</p>
            <div className="container">
                <img 
                    src={img}
                    id="how"
                    height="300px"
                    width="300px"
                    onMouseEnter={()=>{setMagnify(100)}} 
                    onMouseOut={()=>{setMagnify(0)}}
                />
                <div id='zoom' className={`zoom-preview opacity-${magnify} rounded-circle`}></div>
            </div> */}
        </div>
    );
}

export default Test;
