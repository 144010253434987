import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import SearchBar from './SearchBar';
import indian from '../img/indian.png';
import american from '../img/flag.png';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState();
  const [description, setDescription] = useState('');
  const [allert, setAllert] = useState(0);
  console.log(email)

  const submitHandler = async () => {
    console.log('email buttton clicked')
    await fetch('https://jdtsnautodialback.onrender.com/newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, phone, description })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // If the response is successful (status code 200), handle it
        setAllert(1);
        alert('Email sent Successfully')
        console.log("email sent succesfully");
      })
      .catch(error => {
        // Handle errors here
        alert('Please enter registered email')
        console.error('Error:', error.message);
      });
  }

  const databaseManager = async () => {
    console.log('paragraph clicked');
    console.log(name,email,phone,description)
    await fetch('https://jdtechnicalsolution.com/form/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "name":name,"email": email,"phone": phone,"description": description })

    })
      .then(response => {
        if (!response.ok) {
          console.log(response)
          throw new Error('Network response was not ok');
        }
        // If the response is successful (status code 200), handle it
        console.log("data sent succesfully",response.json());
      })
      .catch(error => {
        // Handle errors here
        console.error('Error:', error.message);
      });
  }
  useEffect(() => {
    document.addEventListener('scroll', () => {
      // console.log(window.scrollY)
    })
  })
  return (
    <>
      <div className="container-fluid contact bg-light py-5">
        <div className="container py-5">
          <div className="mx-auto text-center mb-5" style={{ maxWidth: "900px" }}>
            <span className="fw-bolder section-title px-3">CRUSH YOUR COMEPETITION NOW</span>
            <p className="mb-0 fs-md-1 fs-5 fw-bolder" style={{ color: 'rgb(0,0,0)' }}>Contact For Any Query</p>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-4">
              <div className="bg-white rounded p-4">
                <div className="text-center mb-4">
                  <i className="fa fa-map-marker-alt fa-3x" style={{ color: 'rgb(15, 195, 250)' }}></i>
                  <h4 className="text-dark">Address</h4>
                  <p className="mb-0 text-secondary">B1 ,Soilder Tower, Commercial Complex <br /> New Delhi, 110018</p>
                </div>
                <div className="text-center mb-4">
                  <i className="fa fa-phone-alt fa-3x mb-3" style={{ color: 'rgb(15, 195, 250)' }}></i>
                  <h4 className="text-dark">Mobile</h4>
                  <img className="flags" src={indian} alt="" /> <Link to="tel: +91 9971979794" className="mb-0 text-secondary" style={{ textDecoration: 'none' }}>+91 9971979794</Link><br />
                  <img className='flags' src={american} alt="" /><Link to="tel: +1-888-821-5749" className="mb-0 text-secondary" style={{ textDecoration: 'none' }}>+1-888-821-5749</Link>
                </div>

                <div className="text-center">
                  <i className="fa fa-envelope-open fa-3x mb-3" style={{ color: 'rgb(15, 195, 250)' }}></i>
                  <h4 className="text-dark">Email</h4>
                  <Link to="mailto:?support@jdtechnicalsolution.com" className="mb-0 text-secondary" style={{ textDecoration: 'none' }}>support@jdtechnicalsolution.com</Link>



                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <h3 className="mb-2">We listen to the voice of every costumer.</h3>
              <p className="mb-4">Please email us using the form below if you require any assistance or have any questions:</p>
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control border-0" id="name" placeholder="Your Name" onChange={(e) => { setName(e.target.value) }} />
                      <label htmlFor="name">Your Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control border-0" id="email" placeholder="Your Email" onChange={(e) => { setEmail(e.target.value) }} />
                      <label htmlFor="email">Your Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control border-0" id="subject" placeholder="" onChange={(e) => { setPhone(e.target.value) }} />
                      <label htmlFor="subject">Phone Number</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control border-0" placeholder="Leave a message here" id="message" style={{ height: "160px" }} onChange={(e) => { setDescription(e.target.value) }}></textarea>
                      <label htmlFor="message">Breif description of your business...</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100" id="liveToastBtn" type="button" onClick={submitHandler}><p className='py-3 mb-0' onClick={databaseManager}>Send Message</p></button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12 mt-5">
              <div className="rounded mt-5">
                <iframe className="rounded w-100"
                  style={{ height: "450px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.798139960628!2d-112.2251754!3d33.4769282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b15685e6918fd%3A0x9f863dec39208b33!2s7611%20W%20Thomas%20Rd%2C%20Phoenix%2C%20AZ%2085033%2C%20USA!5e0!3m2!1sen!2s!4v1620264744231!5m2!1sen!2s"
                  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="rounded mt-5">
                <iframe className="rounded w-100"
                  style={{ height: "450px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.080717244277!2d77.0747779!3d28.6391439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3d39cfaf17d%3A0x7bfa7b4c749eb83e!2sRajiv%20Chowk!5e0!3m2!1sen!2sin!4v1649711464079!5m2!1sen!2sin"
                  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
