import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import jdLogo from '../img/JDTlogo.png';
import { homeScroll } from '../smoothScroll';
export default function Newnav() {
    const [navColor, setNavColor] = useState('rgb(0,0,0,.1)');
    const [linkColor, setLinkColor] = useState('white')

    const handlemouseover = () => {
        const product = document.getElementById('product');
        product.style.zIndex = '11'
        product.style.opacity = '1'
        if (window.innerWidth > 576) {
            product.style.top = '44px'
        }
        else {
            product.style.top = '50px'
        }
    }
    const handlemouseleave = () => {
        const product = document.getElementById('product');
        product.style.zIndex = '1'
        product.style.top = '-550px'
    }
    useEffect(() => {
        if (window.innerWidth < 728) {
            setNavColor('rgba(15,195,250,.8)');
            setLinkColor('black')
        }
    })
    return (
        <>
            <nav id='navbar' className="navbar navbar-expand-lg z-index-5 pt-1" style={{}}>
                <div id="nav" className="container-fluid" >
                    <Link onClick={() => { homeScroll('nav') }} to='/'><img src={jdLogo} className=" sticky-top navbar-brand" alt="" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style={{ width: '80%' }}>
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Mai Menu</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                {/* <li className="nav-item mx-md-2 ">
                                    <div onClick={() => { homeScroll('form') }}><Link className="nav-link active" aria-current="page" to="/" style={{ color: `${linkColor}` }}>About</Link></div>
                                </li> */}
                                {/*
                                window.innerWidth < 844 && <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" href="/" id="navbarDropdown2" role="button"
                                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                        Product
                                    </Link>
                                    <ul className="dropdown-menu border-0" aria-labelledby="navbarDropdown2">
                                        <li className="dropdown">
                                            <Link className="dropdown-item dropdown-toggle" href="/" id="nestedDropdown" role="button"
                                                data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                                Servers
                                            </Link>
                                            <ul className="dropdown-menu border-0" aria-labelledby="nestedDropdown">
                                                <li className='bg-info'><a className="dropdown-item" href="/singleProduct">HPE Proliant DL560 Gen9 Server</a></li>
                                                <li className='bg-light'><a className="dropdown-item" href="/">Dell PowerEdge R720 Server</a></li>
                                                <li className='bg-info'><a className="dropdown-item" href="/">Dell PowerEdge R750 Server</a></li>
                                                <li className='bg-light'><a className="dropdown-item" href="/">HPE ProLiant DL180 Gen10 Server</a></li>
                                                <li className='bg-info'><a className="dropdown-item" href="/">Cisco UCS C220 M5 Server</a></li>
                                                <li className='bg-light'><a className="dropdown-item" href="/">HP Apollo 4200 Gen9 Server</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link className="dropdown-item dropdown-toggle" href="/" id="nestedDropdown" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Workstations
                                            </Link>
                                            <ul className="dropdown-menu border-0" aria-labelledby="nestedDropdown">
                                                <li className='bg-info'><Link className="dropdown-item" href="/">HP Z4 G4 Workstation</Link></li>
                                                <li className='bg-light'><Link className="dropdown-item" href="/">Precision T7920</Link></li>
                                                <li className='bg-info'><Link className="dropdown-item" href="/">Dell Precision T7600 Workstation</Link></li>
                                                <li className='bg-light'><Link className="dropdown-item" href="/">HP Z440 Workstation Reliable</Link></li>
                                                <li className='bg-info'><Link className="dropdown-item" href="/">HP Z440 Workstation (Standard)</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link className="dropdown-item dropdown-toggle" href="/" id="nestedDropdown" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Routers
                                            </Link>
                                            <ul className="dropdown-menu border-0" aria-labelledby="nestedDropdown">
                                                <li className='bg-info'><Link className="dropdown-item" href="/">JUNIPER PTX3000BASE</Link></li>
                                                <li className='bg-light'><Link className="dropdown-item" href="/">Mikrotik CCR1072-1G-8S-plus</Link></li>
                                                <li className='bg-info'><Link className="dropdown-item" href="/">Huawei AR G3 AR3200 Series</Link></li>
                                                <li className='bg-light'><Link className="dropdown-item" href="/">Integrated Enterprise Router</Link></li>
                                                <li className='bg-info'><Link className="dropdown-item" href="/">Cisco 2900 Series Routers</Link></li>
                                                <li className='bg-light'><Link className="dropdown-item" href="/">UBNT ER-8-XG</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link className="dropdown-item dropdown-toggle" href="/" id="nestedDropdown" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Switches
                                            </Link>
                                            <ul className="dropdown-menu border-0" aria-labelledby="nestedDropdown">
                                            <li className='bg-info'><Link className="dropdown-item" href="/">Aruba 6100 Series Switch</Link></li>
                                            <li className='bg-light'><Link className="dropdown-item" href="/">Dell EMC PowerSwitch S series</Link></li>
                                            <li className='bg-info'><Link className="dropdown-item" href="/">Huawei Data Center Switches CE 8800 Series</Link></li>
                                            <li className='bg-light'><Link className="dropdown-item" href="/">Juniper EX4300 Series Ethernet Switches</Link></li>
                                            <li className='bg-info'><Link className="dropdown-item" href="/">Link Core Switch Line Cards</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link className="dropdown-item dropdown-toggle" href="/" id="nestedDropdown" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                Firewalls
                                            </Link>
                                            <ul className="dropdown-menu border-0" aria-labelledby="nestedDropdown">
                                                <li className='bg-info'><Link className="dropdown-item" href="/">Palo Alto Networks Next-Generation Firewall</Link></li>
                                                <li className='bg-light'><Link className="dropdown-item" href="/">Check Point Firewall</Link></li>
                                                <li className='bg-info'><Link className="dropdown-item" href="/">Juniper Networks SRX Series</Link></li>
                                                <li className='bg-light'><Link className="dropdown-item" href="/">Sophos XG Firewall</Link></li>
                                            </ul>
                                        </li>
                                    </ul> 
                                    </li>*/}
                                {/*
                                window.innerWidth > 844 && <li className="nav-item mx-2" onMouseOver={handlemouseover} onMouseLeave={handlemouseleave}>
                                    <Link className="nav-link active text-light" aria-current="page" to="#">Product <i class="bi bi-caret-down-fill"></i></Link>
                                    <div id='product' className='position-absolute border-primary container'>
                                        <div className='row'>
                                            <div className='col-md-3 col-6'>
                                                <Link to="/" className="btn-hover-bg btn btn-sm btn-primary rounded-0 text-white shadow mt-3" style={{ backgroundColor: ' ', width: '200px', align: '' }}>Servers</Link><br />
                                                <span style={{ fontSize: '13px', fontWeight: '500', lineHeight: '2.1' }}>
                                                    <Link to='/singleProduct' style={{ textDecoration: 'none', color: 'black' }}>HPE Proliant DL560 Gen9 Server</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Dell PowerEdge R720 Server</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Dell PowerEdge R750 Server</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>HPE ProLiant DL180 Gen10 Server</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Cisco UCS C220 M5 Server</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>HP Apollo 4200 Gen9 Server</Link>
                                                </span>
                                            </div>
                                            <div className='col-md-3 col-6 mb-3'>
                                                <Link to="/" className="btn-hover-bg btn btn-sm btn-primary rounded-0 text-white shadow mt-3" style={{ backgroundColor: ' ', width: '200px', align: '' }}>Workstations</Link><br />
                                                <span style={{ fontSize: '13px', fontWeight: '500', lineHeight: '2.1' }}>
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>HP Z4 G4 Workstation</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Precision T7920 </Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Dell Precision T7600 Workstation</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>HP Z440 Workstation Reliable</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>HP Z440 Workstation (Standard)</Link>
                                                    <Link to="/" className="btn-hover-bg btn btn-sm btn-primary rounded-0 text-white shadow" style={{ backgroundColor: ' ', width: '200px'}}>Firewalls</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Palo Alto Networks Next-Generation Firewall</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Check Point Firewall</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Juniper Networks SRX Series </Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Sophos XG Firewall</Link><br />
                                                

                                                </span>
                                            </div>
                                            <div className='col-md-3 col-6'>
                                                <Link to="/" className="btn-hover-bg btn btn-sm btn-primary rounded-0 text-white shadow mt-3" style={{ backgroundColor: ' ', width: '200px', align: '' }}>Routers</Link><br />
                                                <span style={{ fontSize: '13px', fontWeight: '500', lineHeight: '2.1' }}>
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>JUNIPER PTX3000BASE</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Mikrotik CCR1072-1G-8S-plus</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Huawei AR G3 AR3200 Series Integrated Enterprise Router</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Cisco 2900 Series Routers</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>UBNT ER-8-XG</Link>
                                                </span>
                                            </div>
                                            <div className='col-md-3 col-6 '>
                                                <Link to="/" className="btn-hover-bg btn btn-sm btn-primary rounded-0 text-white shadow mt-3" style={{ backgroundColor: ' ', width: '200px', align: '' }}>Switches</Link><br />
                                                <span style={{ fontSize: '13px', fontWeight: '500', lineHeight: '2.1' }}>
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Aruba 6100 Series Switch </Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Dell EMC PowerSwitch S series</Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Huawei Data Center Switches CE 8800 Series </Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Juniper EX4300 Series Ethernet Switches </Link><br />
                                                    <Link style={{ textDecoration: 'none', color: 'black' }}>Link Core Switch Line Cards</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                */}
                                {window.innerWidth < 844 &&
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" href="/" id="navbarDropdown3" role="button"
                                            data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                            Business Development
                                        </Link>
                                        <ul className="dropdown-menu border-0" aria-labelledby="navbarDropdown3">
                                            {/* <Link to='/cloudDevelopment' className='dropdown-item'>Cloud Development</Link> */}
                                            <li className="dropdown">
                                                <Link className="dropdown-item dropdown-toggle" to="/" id="nestedDropdown" role="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Digital Marketing
                                                </Link>
                                                <ul className="dropdown-menu border-0" aria-labelledby="nestedDropdown">
                                                    <li className='bg-info'><Link className="dropdown-item" to="/googleAds">Google Ads</Link></li>
                                                    <li className='bg-light'><Link className="dropdown-item" to="/seo">Google SEO</Link></li>
                                                    <li className='bg-info'><Link className="dropdown-item" to="/metaAds">Meta Ads</Link></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown">
                                                <Link className="dropdown-item dropdown-toggle" href="/" id="nestedDropdown" role="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Managed Services
                                                </Link>
                                                <ul className="dropdown-menu border-0" aria-labelledby="nestedDropdown">
                                                    {/* <li className='bg-info'><Link className="dropdown-item" to="/chatBot">AI Based Chatbot</Link></li>
                                                    <li className='bg-info'><Link className="dropdown-item" to="/IT_Security">IT Security</Link></li> */}
                                                    <li className='bg-info'><Link className="dropdown-item" to="/theAutodial">The Autodial</Link></li>
                                                </ul>
                                            </li>
                                            <Link to='graphicDesigning' className='dropdown-item'>Graphic Desigining</Link>
                                            <Link to="/softwareDevelopment" className='dropdown-item'>Software Development</Link>
                                            <Link to="/websiteDevelopment" className='dropdown-item'>Website Development</Link>
                                        </ul>

                                    </li>}
                                {window.innerWidth > 844 &&
                                    <>
                                        <li className="nav-item mx-2">
                                            <div className="sec-center nav-link">
                                                <input className="dropdown-nav" type="checkbox" id="dropdown" name="dropdown" />
                                                <label className="for-dropdown" for="dropdown">Business Development<i class="bi bi-caret-down-fill"></i></label>
                                                <div className="section-dropdown">
                                                    {/* <Link className="link" to="/cloudDevelopment">Cloud Development<i className="uil uil-arrow-right"></i></Link> */}
                                                    {/* <Link className="link" to="/">Business Development<i className="uil uil-arrow-right"></i></Link> */}
                                                    <input className="dropdown-sub" type="checkbox" id="dropdown-sub" name="dropdown-sub" />
                                                    <label className="for-dropdown-sub" for="dropdown-sub">Digital Marketing<i class="bi bi-caret-down-fill"></i></label>
                                                    <div className="section-dropdown-sub">
                                                        <Link className="link" to="/googleAds">Google Ads<i className="uil uil-arrow-right"></i></Link>
                                                        <Link className="link" to="/seo">Google SEO<i className="uil uil-arrow-right"></i></Link>
                                                        <Link className="link" to="/metaAds">Meta Ads<i className="uil uil-arrow-right"></i></Link>
                                                    </div>
                                                    <input className="dropdown-sub2" type="checkbox" id="dropdown-sub2" name="dropdown-sub2" />
                                                    <label className="for-dropdown-sub2" for="dropdown-sub2">Managed Services<i class="bi bi-caret-down-fill"></i></label>
                                                    <div className="section-dropdown-sub2">
                                                        {/* <Link className="link" to="/chatBot">AI Based ChatBot<i className="uil uil-arrow-right"></i></Link>
                                                        <Link className="link" to="/IT_Security">IT Security<i className="uil uil-arrow-right"></i></Link> */}
                                                        <Link className="link" to="/theAutodial">The Autodial<i className="uil uil-arrow-right"></i></Link>
                                                    </div>
                                                    <Link className="link" to="/graphicDesigning">Graphic Designing<i className="uil uil-arrow-right"></i></Link>
                                                    <Link className="link" to="/softwareDevelopment">Software Development<i className="uil uil-arrow-right"></i></Link>
                                                    <Link className="link" to="/websiteDevelopment">Website Development<i className="uil uil-arrow-right"></i></Link>
                                                </div>
                                            </div>
                                        </li> </>}
                                {/* <li className="nav-item mx-md-2">
                                    <Link className="nav-link" to="/" style={{ color: `${linkColor}` }}>Plan</Link>
                                </li>
                                <li className="nav-item mx-md-2">
                                    <Link to='/contact' className="nav-link" style={{ color: `${linkColor}` }}>Contact</Link>
                                </li> */}
                            </ul>
                            <div onClick={() => { homeScroll('form') }}><Link to="/contact" className="btn-hover-bg btn btn-sm btn-primary rounded-pill text-white px-4 ms-lg-4 pt-2" style={{ backgroundColor: ' ' }}>&nbsp;&nbsp;Contact us Today!&nbsp;&nbsp;</Link></div>
                        </div>
                    </div>
                </div>
            </nav>

        </>
    )
}
