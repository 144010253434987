import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import whatSEO from '../../../img/google_ads.png';
import seo1 from '../../../img/seo1.webp';
import seo2 from '../../../img/seo2.jpeg';
import seo3 from '../../../img/seo3.webp';
import seo4 from '../../../img/seo4.webp';
import seo5 from '../../../img/seo5.webp'
import { homeScroll } from '../../../smoothScroll';
import LogContext from '../../../context/LogContext';
export default function Seo() {
    const myContext = useContext(LogContext);
    const [currencyRatio, setConverterRatio] = useState();
    const [currencyType, setCurrencyType] = useState();
    useEffect(() => {
        setConverterRatio(parseFloat(myContext.currencyRatio));
        setCurrencyType(myContext.currencyType);
    }, [myContext]);
    return (
        <div className='seo my-5'>
            <div className='what-is-seo row p-0'>
                <div className='col-6'>
                    <div className='text'>
                        <p className='head'>What is SEO?</p>
                        <p className='content'>
                            SEO, or Search Engine Optimization, is the process of enhancing a website's visibility and ranking in search engine results pages (SERPs). It involves various strategies like keyword research, on-page optimization, off-page optimization, technical SEO, content creation, local SEO, and user experience optimization. These strategies aim to make your website more relevant and authoritative, ultimately increasing organic traffic and helping you achieve your business objectives.
                        </p>
                    </div>
                </div>
                <div className='col-6 px-5'>
                    <img className="img" src={whatSEO} alt="" />
                </div>
            </div>
            <div className='why-seo my-5'>
                <p className='head mx-md-5'>Why go for SEO?</p>
                <div className='row container-fluid cards p-0'>
                    <div className='col-6 p-5'>
                        <p className='head2'>Brand Awareness</p>
                        <p className='content2'>
                            Branding is not just about developing a visual identity anymore. It's an experience that governs how your customers feel and relate to your brand.
                            We create the essence your brand needs through our SEO strategy. We do that by using the top keywords which your customers are using very often, and will use in the future. So, that we can help your site to be found with a well-known brand.
                        </p>
                    </div>
                    <div className='col-6'>
                        <img className='img' src={seo1} alt="" />
                    </div>
                    <div className='col-6 px-5'>
                        <img className='img' src={seo2} alt="" />
                    </div>
                    <div className='col-6 p-5'>
                        <p className='head2'>Improved Traffic</p>
                        <p className='content2'>
                            It can be done by using various search queries that need high-quality search engine optimization. So, after we will implement our advanced SEO techniques, then you can see a quick rise in the number of visitors to your site, search traffic, and your overall conversion rate.
                        </p>
                    </div>
                    <div className='col-6 p-5'>
                        <p className='head2'>Enhanced User Experience</p>
                        <p className='content2'>
                            SEO focuses more on the site and its relevant data for increasing the content visibility on different search engines. But in SEO we should also consider the users as users are most important for any business. We can do this by including images and video; using a call to action throughout the site; using clear headers; organizing and formatting content to make it easy to digest; or adding links to other relatable content.
                        </p>
                    </div>
                    <div className='col-6'>
                        <img className='img' src={seo3} alt="" />
                    </div>
                    <div className='col-6 px-5'>
                        <img className='img' src={seo4} alt="" />
                    </div>
                    <div className='col-6 p-5'>
                        <p className='head2'>Better Conversion</p>
                        <p className='content2'>
                            SEO and CRO are directly proportional to each other as SEO focuses more on boosting traffic to your site, while CRO aims to convert that traffic by encouraging more people to take action, whether that be signing up for your newsletter, filling out a contact form, or purchasing. In other words, CRO helps you convert more of the traffic you drive with SEO.
                        </p>
                    </div>
                    <div className='col-6 p-5'>
                        <p className='head2'>Good Marketing</p>
                        <p className='content2'>
                            SEO is key to success for businesses nowadays. Because it increases overall-
                            <br />
                            -  Visibility (more traffic, more sessions, more shares).
                            <br />
                            - On-page conversions.
                            <br />
                            -  Reach on SERPs compared to competitors.
                            <br />
                            - And it decreases investments needed on paid channels (social ads, boosts, etc ).
                            <br />
                            So we can say SEO helps in getting better ROI.
                        </p>
                    </div>
                    <div className='col-6'>
                        <img className='img' src={seo5} alt="" />
                    </div>
                </div>
            </div>
            <div className='our-approach p-5'>
                <p className='head'>Our SEO Development Approach</p>
                <p className='content'>
                    Ready to take flight with SEO? With a full-service digital marketing agency like Newbird, all your marketing efforts are meticulously planned, tracked, and monitored. We take a comprehensive approach to SEO, offering a range of services designed to propel your business to the top of search results for key target phases.
                </p>
                <div className='row container'>
                    <div className='col-md-6 p-5'>
                        <p className='head2'>SEO Audit</p>
                        <p className='content2'>
                            To identify fundamental issues affecting your business’s organic search performance, we offer a complimentary SEO audit. This is the initial step in determining the most suitable digital marketing strategy for your company before entering into a formal agreement with us.
                        </p>
                    </div>
                    <div className='col-md-6 p-5'>
                        <p className='head2'>SEO Strategy</p>
                        <p className='content2'>
                            After the SEO audit, we conduct a competitive analysis and create a roadmap that aligns with your business goals. We set timelines for each step and allocate the necessary resources and budget accordingly. This encompasses tasks such as keyword research, content calendar creation, and ongoing SEO planning.
                        </p>
                    </div>
                    <div className='col-md-6 p-5'>
                        <p className='head2'>SEO Execution</p>
                        <p className='content2'>
                            Our team then carries out the necessary actions to achieve the desired outcomes. This may involve on-page optimizations, metadata creation, content creation, fixing broken links, and link building. We work with you to ensure that the changes made are in line with your brand and messaging and supports your businesses goals.
                        </p>
                    </div>
                    <div className='col-md-6 p-5'>
                        <p className='head2'>Analytic & Reporting</p>
                        <p className='content2'>
                            We provide regular reports to evaluate the success of our marketing efforts and optimize strategies. Key performance indicators (KPIs) such as organic web traffic, keyword growth, and target keyword rankings are continuously tracked to guide future marketing strategies.
                        </p>
                    </div>

                </div>
            </div>
            <div className='seo-plans'>

            </div>
            <div className='why-us'>
                <div className='row container mx-0 bg-white'>
                    <p className='head'>
                        Why take SEO Services from us?
                    </p>
                    <div className='col-6 p-5'>
                        <p className='head2'>We're Commited</p>
                        <p className='content'>
                            JDTS offers their clients the committed services, in a given interval of time.
                        </p>
                    </div>
                    <div className='col-6 p-5'>
                        <p className='head2'>We're Skilled</p>
                        <p className='content'>
                            JDTS has highly-skilled professionals who do the work according to the client's needs and wants.                        </p>
                    </div>
                    <div className='col-6 p-5'>
                        <p className='head2'>We're Adaptable</p>
                        <p className='content'>
                            JDTS belongs to the adaptive category of digital marketers which means we can take out profit for our clients in clumsy times also.                         </p>
                    </div>
                    <div className='col-6 p-5'>
                        <p className='head2'>We Give Results</p>
                        <p className='content'>
                            JDTS gives results to its clients even for their small ideas also and converts them into a big business.</p>
                    </div>
                </div>
            </div>
            <div onClick={() => { homeScroll('form') }} className='seo-btn'><Link to='/contact' className='btn btn-primary rounded-pill bubble-btn'>Get the Services Today!</Link></div>
            <div className='container-fluid'>
                <div className='text-center fs-1 fw-bolder my-5'>Our SEO Plans</div>
                <div className='row seo-plan p-5'>
                    <div className='col-md-4 col-12 card shadow rounded'>
                        <div className='h-100'>
                            <p className='fs-5 fw-bold text-center'><span className='fs-2'>Basic</span><br />{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*20000).toFixed(2)}<br /></p>
                            <ul className='why-chooseUs'>
                                <li>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> 100+ keywords</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Single State</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Upto 30 Pages Optimizes</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Duplicate Content Analysis</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Dedicated Internet Marketer</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Initial visitor profile report</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Google Analytics traffic analysis</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Google Analytics setup w/ conversion tracking</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 col-12  card shadow rounded'>
                        <div className=' h-100'>

                            <p className='fs-5 fw-bold text-center'><span className='fs-2'>Advanced</span><br />{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*40000).toFixed(2)}<br /></p>
                            <ul className='why-chooseUs'>
                                <li>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> 200+ keywords</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Multiple States</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Upto 40 Pages Optimized</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Duplicate Content Analysis</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span>  Dedicated Internet Marketer</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Initial visitor profile report</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Google Analytics traffic analysis</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Google Analytics setup w/ conversion tracking</span>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className='col-md-4 col-12 card shadow rounded'>
                        <div className='h-100 '>
                            <p className='fs-5 fw-bold text-center'><span className='fs-2'>Premium</span><br />{currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*80000).toFixed(2)}<br /></p>
                            <ul className='why-chooseUs'>
                                <li>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> 500+ keywords</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> All Over India</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Upto 50 Pages Optimized</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Duplicate Content Analysis</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Dedicated Internet Marketer</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Initial visitor profile report</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Google Analytics traffic analysis</span>
                                </li>
                                <li className='widget'>
                                    <i style={{ color: '#3FE33F' }} aria-hidden="true" class="fas fa-check-circle"></i>
                                    <span> Google Analytics setup w/ conversion tracking</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
