import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function ProductNav() {
  const [navitemSize,setNavItemSize]=useState([0,50,0]);
  const[navItemPosition,setNavItemPosition]=useState([0,0,0])
  useEffect(()=>{
    if(window.innerWidth>576){
      setNavItemSize([0,350,0])
    }
    else{
      setNavItemSize([0,50,0])
    }
  })
  return (
    <>
      {/* <div ></div>
      <nav className="navbar navbar-expand-lg container-fluid my-0" style={{ background: 'rgb(15,195,255,.2)', height: '45px' }}>
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#"></a>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <form className="d-flex" role="search" style={{ marginLeft: '22.5rem' }}>
                <div class="btn-group rounded-end-0">
                  <button class="btn btn-sm btn-secondary" type="button">
                    <span style={{ fontSize: '14px' }}>All</span>
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary rounded-end-0 dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li><Link className='dropdown-item'>Firewalls</Link></li>
                    <li><Link className='dropdown-item'>Routers</Link></li>
                    <li><Link className='dropdown-item'>Servers</Link></li>
                    <li><Link className='dropdown-item'>Stroage Servers</Link></li>
                    <li><Link className='dropdown-item'>Switches</Link></li>
                    <li><Link className='dropdown-item'>Workstations</Link></li>
                  </ul>
                </div>
                <input className="form-control rounded-0" style={{ width: '350px' }} type="search" placeholder="Search" aria-label="Search" />
                <button className="btn btn-warning rounded-start-0" type="submit">Search</button>
              </form>
            </ul>
            <div className="nav-link disabled position-relative fw-bold" aria-disabled="true" style={{ left: '-2%' }}>
              <i className="bi bi-telephone-fill fs-2" style={{ color: 'rgb(15, 195, 250)' }}></i>
            </div>
            <div className='position-relative' style={{ left: '-1%', lineHeight: '1.3', fontSize: '12px', fontFamily: 'work sans, Arial, sans-serif' }}>
              <span className='fw-bold text-secondary'> Hotline</span><br /><span style={{ color: 'rgb(0,0,0,)', fontSize: '15px', fontWeight: 'bolder' }}> +91 9971979794</span>
            </div>
            <div className="nav-link disabled position-relative fw-bold" aria-disabled="true" style={{ left: '-80%' }}>
            </div>
            <div className='position-relative border-0 rounded py-1 px-4' style={{ left: '-79%', lineHeight: '1', fontSize: '11px', fontFamily: 'cursive', background: 'rgb(15,195,250)' }}>
              <span className='fw-bold text-secondary'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delever to</span><br /><span style={{ color: 'white', fontSize: '16px', fontWeight: 'bolder' }}><i class="bi bi-geo-alt-fill fs-5 text-dark"></i>&nbsp;&nbsp;India</span>
            </div>
          </div>
        </div>
      </nav> */}
      <div className='container-fluid my-0 d-flex nav-product p-0' style={{ background: 'rgb(15,195,255,.2)', height: '45px' }}>
      {window.innerWidth>844 &&<>
        {/* <div className="nav-link disabled position-relative fw-bold" aria-disabled="true" style={{ left: '-80%' }}>
        </div> */}
        <div className='delevering-to position-relative border-0 rounded py-1 px-4' style={{background: 'rgb(15,195,250)' }}>
          <span className='fw-bold text-secondary'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delever to</span><br /><span style={{ color: 'white', fontSize: '16px', fontWeight: 'bolder' }}><i class="bi bi-geo-alt-fill fs-5 text-dark"></i>&nbsp;&nbsp;India</span>
        </div>
        </>}
        <form className="d-flex position-relative" role="search" style={{ marginLeft: '' }}>
          <div class="btn-group rounded-end-0">
            <button class="btn btn-sm btn-secondary" type="button">
              <span style={{ fontSize: '14px' }}>All</span>
            </button>
            <button type="button" class="btn btn-sm btn-secondary rounded-end-0 dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu">
              <li><Link className='dropdown-item'>Firewalls</Link></li>
              <li><Link className='dropdown-item'>Routers</Link></li>
              <li><Link className='dropdown-item'>Servers</Link></li>
              <li><Link className='dropdown-item'>Stroage Servers</Link></li>
              <li><Link className='dropdown-item'>Switches</Link></li>
              <li><Link className='dropdown-item'>Workstations</Link></li>
            </ul>
          </div>
          <input className="form-control rounded-0" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-warning rounded-start-0" type="submit">Search</button>
        </form>
        {window.innerWidth>844 &&<><div className="nav-link disabled telephone-icon position-relative fw-bold" aria-disabled="true">
          <i className="bi bi-telephone-fill " style={{ color: 'rgb(15, 195, 250)' }}></i>
        </div>
        <div className='position-relative hotline'>
          <span className='fw-bold text-secondary hotline-text'> Hotline</span><br /><span className='hotline-no'style={{ color: 'rgb(0,0,0,)'}}> +91 9971979794</span>
        </div></>}
      </div>
      {window.innerWidth<844 &&<div className='container-fluid text-center' style={{backgroundColor:'rgb(15,195,255,.2)'}}>
        <i class="bi bi-geo-alt-fill fs-5 text-dark"></i><span style={{fontSize:'12px'}}>Delevering to India</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link style={{textDecoration:'none',fontSize:'13px'}}>Update Location</Link>
      </div>}
    </>
  )
}
