import React from 'react'

export default function Subscribe() {
  return (
    <>
        <div className="container-fluid subscribe py-5">
            <div className="container text-center py-5">
                <div className="mx-auto text-center" style={{maxWidth:"900px"}}>
                    <h5 className="subscribe-title fs-2 px-3">Crush Your Competetion Now</h5>
                    <h1 className="text-white fs-4 mb-4">Our Newsletter</h1>
                    <p className="text-white mb-5">At JDTS, we're committed to driving your business forward through an array of premier services and top-of-the-line products. Specializing in Automation,Digital Marketing, Website Development, Software Development, and Media Development, we are your one-stop destination for all your digital needs.Send us a breif information on your business model to get a free advice from a experienced Business Developer.
                    </p>
                    <div className="position-relative mx-auto">
                        <input className="form-control border-primary rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" className="btn btn-primary rounded-pill position-absolute top-0 end-0 py-md-2  px-md-4 px-1 mt-2 me-2"><span className='GYFCN'>Get your Free Consultation Now !</span></button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
