import React, { useEffect, useState } from 'react';
import {back} from './back.js';
import img1 from '../img/Banner3.png'
import carousel1 from '../img/adwordsRes1.png'
import carousel2 from '../img/adwordsRes2.png'
import carousel3 from '../img/adwordsRes3.png'
import carousel4 from '../img/adwordsRes4.png'
function Result() { 
  const [magnify,setMagnify] = useState(0);

//   useEffect(()=>{back()},[])
  return (
    <>
        <div className='result-googleAdwords container-fluid bg-primary text-center pt-5 pb-5'>
            <span className='fw-bolder head1'>Google Adwords Results <br /></span>
                <div className='fw-bolder position-relative head2'>WITH 6X+ ROAS+</div>
                <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="2000">
                            <img src={carousel1} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={carousel2} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={carousel3} className="d-block w-100" data-bs-interval="2000" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={carousel4} className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
    </>
  );
}

export default Result;
