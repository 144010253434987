import React from 'react';
import ProductDesign from './GraphicDesigningCarsouls/ProductDesign';
import LogoDesign from './GraphicDesigningCarsouls/LogoDesign';
import FestivalPost from './GraphicDesigningCarsouls/FestivalPost';
import aboutImg from '../../../img/services-motion-3d-1.gif'
export default function PhotosDevelopment() {
  return (
    <>
    {/* <div class="Graphic-custom-shape-divider-bottom-1714370669">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
  </div> */}
   
    <div className='graphic-designing py-5' style={{background:''}}>
      <div className='about'>
        <p className='head'>Graphic <br /> Designing</p>
        <div className='row container-fluid p-0'>
          <div className='col-6 p-0'>
          <img className="img"src={aboutImg} alt="" />
          </div>
          <div className='col-6'>
              <p className='head2 p-0 text-white'>
              A Picture Is Worth A Thousand Words
              </p>
              <p className='content'>
                We believe a brand's image is every bit as important as their product or service.
                We've been trusted with thousands of print ads, editorials, product lines graphic designs.
                We offer a wide range of commercial graphic designing services from product and lifestyle to fashion and architectural.
                As a premier photography studio in New Delhi, we have the equipment, talent and resources to bring your brand to life through imagery. In today's booming digital age - quality images aren't a luxury, they're a necessity. We're here to help you stand out from the crowd. When words become unclear, I shall focus with graphical images and photographs.
              </p>
          </div>
        </div>
      
      {/* <div className='animated-picture'>
      <p className='fs-2 fw-bold text-center text-white'>
          Animated Pictures
        </p>
        <SocialMediaMarketing/>
      </div>
      <div className='3d-motion'>
      <p className='fs-2 fw-bold text-center text-white'>
          3D Motion Design
        </p>
        <SocialMediaMarketing/>
      </div> */}
      <div className='product-design mt-5'>
        <p className='fw-bold text-center mb-0'>
          Product Design
        </p>
        <ProductDesign/>
      </div>
      <div className='logo-design'>
      <p className='fw-bold text-center mb-0'>
          Logo Design
        </p>
        <LogoDesign/>
      </div>    
      <div className='festival-post'>
      <p className='fw-bold text-center text-white mb-0'>
          Festive Design
        </p>
        <FestivalPost/>
      </div>      
    </div>
    </div>
    {/* <div class="Graphic-custom-shape-divider-top-1714371382">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
      </div> */}
    </>
  )
}
