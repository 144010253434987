import React from 'react';
import AboutImage from '../img/care2.png'
export default function About() {
  return (
       <div className="container-fluid about py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5">
            <div className="h-100" style={{ border: '50px solid', borderColor: 'transparent  #0fc3fa transparent  #0fc3fa' }}>
              <img src={AboutImage} className="img-fluid w-100 h-100" alt=""  />
            </div>
          </div>
          <div className="col-lg-7" style={{ background: 'linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .8)), url(img/about-img-1.png)' }}>
            <h5 className="section-about-title pe-3">About Us</h5>
            <h1 className="mb-4">Welcome to <span className='fw-bolder fs-2' style={{color:' #0fc3fa',wordSpacing:'.1px',fontSize:'domine'}}>JD TECHNICAL SOULTION</span></h1>
            <p className="mb-4">Welcome to JD Technical Solution, your one-stop destination for cutting-edge Digital Solutions and Managed Services. At JD Technical Solution, we're passionate about helping businesses thrive in the digital landscape by providing top-notch services in digital marketing, software development, media development, web development, and e-commerce solutions.</p>
            <p className="mb-4">Founded on the principles of innovation and excellence, JD Technical Solution is a dynamic team of professionals dedicated to delivering tailored solutions that drive results. With years of industry experience and a commitment to staying ahead of the curve, we have established ourselves as a trusted partner for businesses seeking to enhance their online presence and achieve their goals.</p>
            <div className="row mt-5 gy-2 gx-4 mb-4">
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Digital Marketing</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Website Development</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Software Development</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Media Development</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Business Development</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Managed Services</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
