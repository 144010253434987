import React, { useContext, useEffect, useState } from 'react'
import ourDemo from '../../../img/websitDevelopment2.webp'
import feature1 from '../../../img/SDfeatures1.jpg';
import feature2 from '../../../img/SDfeatures2.jpg';
import feature3 from '../../../img/SDfeatures3.jpg';
import feature4 from '../../../img/SDfeatures4.jpg';
import feature5 from '../../../img/SDfeatures7.jpg';
import feature6 from '../../../img/SDfeatures6.jpg';
import checkOut from '../../../img/websiteDevelopment3.avif';
import one from '../../../img/1.jpg';
import two from '../../../img/2.jpg';
import three from '../../../img/3.jpg';
import { Link } from 'react-router-dom';
import { homeScroll } from '../../../smoothScroll';
import LogContext from '../../../context/LogContext';

export default function WebsiteDevelopment() {
    const myContext = useContext(LogContext);
    const [currencyRatio, setConverterRatio] = useState();
    const [currencyType, setCurrencyType] = useState();
    useEffect(() => {
        setConverterRatio(parseFloat(myContext.currencyRatio));
        setCurrencyType(myContext.currencyType);
    }, [myContext]);
    return (
        <>
            <div className='website-development'>
                <div className='intro position-relative overflow-hidden' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', minWidth: '200%' }}>
                    <div className="position-relative circle-1" style={{ left: '-25%', top: '-53%', height: '1700px', width: '1700px', border: '100px solid rgb(15,195,255)', borderRadius: '50%', opacity: '.03', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                        <div className="position-relative circle-2" style={{ height: '1300px', width: '1300px', border: '100px solid rgb(15,195,255)', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="position-relative circle-3" style={{ height: '900px', width: '900px', border: '100px solid rgb(15,195,255)', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="position-relative circle-4" style={{ height: '500px', width: '500px', border: '100px solid rgb(15,195,255)', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="position-relative circle-5" style={{ height: '200px', width: '200px', border: '100px solid rgb(15,195,255)', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='intro-text'>
                    <p className='head position-relative'>
                        Brilliant web <br />design, simplified
                    </p>
                    <p className='content'>
                        No matter your industry, Impressive Website Design is important in today’s digital age.
                        If you’re looking to take your Company to the Next level, you need a Automated Web Design to help you Attract and Convert more customers.
                        JDTS is a leading web design agency where we deliver stunning, responsive Designs that are created with conversion in mind.
                    </p>
                    <div className='container-fluid p-md-5 p-1 row features'>
                        <div className='col-md-2 col-4 '>
                            <img src={feature1} className='img' alt="" />
                            <p className='head text-center'>Live Chat</p>
                            <p className='content-features text-center'>
                                Real time chatting with a customers on website not only builds customers confidence during their shopping experience, but also helps to improve your conversion ratio and creates a brand image.
                            </p>
                        </div>
                        <div className='col-md-2 col-4 '>
                            <img src={feature2} className='img' alt="" />
                            <p className='head text-center'>Lighting Speed</p>
                            <p className='content-features text-center'>
                                Site speed and design are two of the most important ranking factors Google takes into consideration, as they have the biggest effect of cutomer staying on site as the website respond faster                            </p>
                        </div>
                        <div className='col-md-2 col-4 '>
                            <img src={feature3} className='img' alt="" />
                            <p className='head text-center'>Responsive Design</p>
                            <p className='content-features text-center'>
                                Responsive Website is the approach that suggests website design and development should respond to the user’s request irrespective of device they use (mobile/ laptop/ desktop/ ipad).                            </p>
                        </div>
                        <div className='col-md-2 col-4 '>
                            <img src={feature4} className='img' alt="" />
                            <p className='head text-center'>Payment Gateway</p>
                            <p className='content-features text-center'>
                                Affordable web design services in India, includes payment gateway for easy online transfer from any customer (debit/ credit…etc) account to your account for any sales website does.                            </p>
                        </div>
                        <div className='col-md-2 col-4 '>
                            <img src={feature5} className='img' alt="" />
                            <p className='head text-center'>Social Media</p>
                            <p className='content-features text-center'>
                                Your social media presence doesn’t have to end on your social profile. By making your website more social media-friendly, you are reaching more & more potential customers online & more sales.                            </p>
                        </div>
                        <div className='col-md-2 col-4 '>
                            <img src={feature6} className='img' alt="" />
                            <p className='head text-center'>24/7 Support</p>
                            <p className='content-features text-center'>
                                We at Zauca, considering the importance of business that we are into, now provides around the clock support to enhance its customer support, which can ensure 99.9% uptime of websites.                            </p>
                        </div>
                    </div>
                    <div className='promote container-fluid p-0'>

                        <p className=' promote-head text-center'>
                            Get the your website with features of promotion and audience buliding at JDTS
                        </p>
                        <p className='promote-content  text-white text-center'>
                            We help you establish your brand, look like a pro, and rise the ranks of search engines using our easy-to-use website builder. Customize your website on a no-code platform so that you don't need to hire anyone else to help.
                            <div onClick={() => { homeScroll('form') }} className='my-5 text-center' style={{ height: '4rem', marginTop: '12px' }}><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded text-white py-md-2 py-1 px-md-3 px-2"><span className=''>Get A Demo Now</span></Link></div>
                        </p>
                    </div>
                    <div className='check-out row'>
                        <div className='col-sm-6 col-6 ps-4 image'>
                            <img src={checkOut} alt="" />
                        </div>
                        <div className='col-sm-6 col-6 paragraph'>
                            <div className='content-inner'>
                                <p className=' head fw-bold'>Decide if JDTS is right for you</p>
                                <p className=' contentt bolder'>
                                    Check out our plans up against the market.
                                </p>
                                {window.innerWidth > 576 &&
                                    <div onClick={() => { homeScroll('plan') }} style={{ height: '4rem', marginTop: '12px', marginLeft: '16rem' }}><Link to="/websiteDevelopment" className="btn-hover-bg btn btn-warning bubble-btn rounded-pill text-white py-md-2 py-1 px-md-3 px-2"><span className=''>Our Plans</span></Link></div>}
                                {window.innerWidth <= 576 &&
                                    <div onClick={() => { homeScroll('plan') }} style={{ height: '4rem' }}><Link to="/websiteDevelopment" className="btn-hover-bg btn btn-warning bubble-btn  rounded-pill text-white py-md-2 py-1 px-md-3 px-2"><span className=''>Our Plans</span></Link></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row container-fluid pricing' style={{ paddingLeft: '3rem' }}>
                        <div className='col-md-4 col-12 rounded text-center'>
                            <img src={one} style={{ width: '150px' }} alt="" />
                            <p className='head'>
                                {currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*5000).toFixed(2)}
                            </p>
                            <p className='plan'>
                                – 1 Free Domain Integration <br />
                                – 5 Page (Single Page Website)<br />
                                – Business Email ID Integration (Webmail)<br />
                                – 5 Banner Images & 1 Videos<br />
                                – Unlimited Speed( On Bandwidth)<br />
                                – 100% Responsive Website<br />
                                - WhatsApp Integration <br />
                                – Live Chat Integration<br />
                                – Payment Gateway Integration<br />
                                – Social Media Integration<br />
                                – 9-6 Support (SLA: 12 Hours)<br />
                                – Annual AMC {currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*4000).toFixed(2)}<br /><br />
                                <br /><br />
                                <p className='fs-6 text-primary' style={{ marginTop: '17px' }}>Note: GST @ 18% Applicable on All Purchase</p>
                                <div className='btn-div' onClick={() => { homeScroll('form') }} style={{ height: '4rem' }}><Link to="/contact" className="btn-hover-bg btn btn-warning bubble-btn rounded-pill text-white py-md-2 py-1 px-md-3 px-2"><span className=''>Get Quote Now</span></Link></div>
                            </p>

                        </div>
                        <div className='col-md-4 col-12  shadow text-center'>
                            <img src={two} style={{ width: '150px' }} alt="" />
                            <p className='head classic-plan'>
                            {currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*25000).toFixed(2)}
                            </p>
                            <p className='plan'>
                                – 1 Free Domain Integration <br />
                                – UL (12) Pages* (Dynamic Website)<br />
                                – Business Email ID Integration (Webmail)<br />
                                – 5 Banner Images, 100Product Images, 1 Videos & 10 GIFs<br />
                                – Unlimited Speed (On Bandwidth)<br />
                                – 100% Responsive Website<br />
                                – Live Chat Integration<br />
                                – Payment Gateway Integration<br />
                                – Social Media Integration<br />
                                – WhatsApp Integration<br />
                                – SSL Certificate<br />
                                – 9-6 Support (SLA: 12 Hours)<br />
                                – Annual AMC {currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*7000).toFixed(2)}
                                <br />
                                <br />
                                <span className='fs-6 text-primary'>Note: GST @ 18% Applicable on All Purchase</span>
                                <div className='btn-div' onClick={() => { homeScroll('form') }}><Link to="/contact" className="btn-hover-bg btn btn-warning bubble-btn rounded-pill text-white py-md-2 py-1 px-md-3 px-2"><span className=''>Get Quote Now</span></Link></div>
                            </p>
                        </div>
                        <div className='col-md-4 col-12 rounded text-center'>
                            <img src={three} style={{ width: '150px' }} alt="" />
                            <p className='head'>
                            {currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*50000).toFixed(2)}
                            </p>
                            <p className='plan'>
                                – 1 Free Domain Integration <br />
                                – UL (20) Pages* (Dynamic Website)<br />
                                – Unlimited Email id (Webmail/ Outlook)<br />
                                – Android Application*<br />
                                – 5 Banners Images, 10GIFs & Videos<br />
                                – Unlimited Speed (On Bandwidth)<br />
                                – 100% Responsive Website<br />
                                – Live Chat Integration<br />
                                – Payment Gateway Integration<br />
                                – Social Media Integration<br />
                                – WhatsApp Integration<br />
                                – SSL Certificate<br />
                                – cPanel* Access<br />
                                – 9-6 Support (SLA: 12 Hours)<br />
                                – Annual Renewal {currencyType == 'INR' && '₹'}{currencyType == 'USD' && '$'}{currencyType == 'EUR' && '€'}{currencyType == 'GBP' && '€'} {(currencyRatio*12000).toFixed(2)}
                                <br /><br />
                                <span className='fs-6 text-primary'>Note: GST @ 18% Applicable on All Purchase</span>
                                <div className="btn-div" onClick={() => { homeScroll('form') }} ><Link to="/contact" className="btn-hover-bg btn btn-warning bubble-btn rounded-pill text-white py-md-2 py-1 px-md-3 px-2"><span className=''>Get Quote Now</span></Link></div>
                            </p>
                        </div>
                    </div>
                    <div>
                        {window.innerWidth > 576 &&
                            <img className='position-relative' style={{ top: '-10rem' }} src={ourDemo} alt="" />
                        }
                        {window.innerWidth <= 576 &&
                            <img className='position-relative w-200' style={{ top: '-16rem', left: '-65%' }} src={ourDemo} alt="" />
                        }
                        {window.innerWidth > 576 &&
                            <div style={{ height: '5rem', top: '-10rem' }} onClick={() => { homeScroll('form') }} className='position-relative text-center'  ><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded text-white py-md-2 py-1 px-md-3 px-2"><span className=''>Get A Demo Now</span></Link></div>
                        }
                        {window.innerWidth < 576 &&
                            <div style={{ height: '5rem', top: '-10rem' }} onClick={() => { homeScroll('form') }} className='position-relative text-center'  ><Link to="/contact" className="btn-hover-bg btn btn-primary bubble-btn rounded text-white py-md-2 py-1 px-md-3 px-2"><span className='fs-2'>Get A Demo Now</span></Link></div>
                        }
                    </div>
                </div>
            </div>
            {/* <img src="https://embed.tawk.to/_s/v4/assets/images/attention-grabbers/21-bl.svg" className='position-fixed' style={{ top: '82.5%', left: '3%', width: '200px', zIndex: '5' }} alt="" /> */}
        </>
    )
}
