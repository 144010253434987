import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Socialmedia() {

  const location= useLocation();
  useEffect(() => {
    let i = 0;
    const colors = ['white', 'green'];
    const interval = setInterval(() => {
      const arrow = document.getElementById('next-video-arrow');
      if (arrow) {
        arrow.style.color = colors[i % 2];
        i++;
      }
    }, 100);
    
    // Clean up function to clear interval when component unmounts
    return () => clearInterval(interval);
  }, []);
  return (
    <> 
    { location.pathname!='/login' &&
     <div className='social-media container-fluid px-0 d-flex flex-nowrap overflow-auto ' style={{backgroundColor:''}} >
      {/* <div id='next-video-arrow'className='next-video-arrow fs-1 fw-bold'>{'>>>>'}</div> */}
      <div className='video-frame mx-0'>
        <iframe className='rounded shadow' width="300" src="https://www.youtube.com/embed/HcqP5e49qEc" title="Digital Marketing/Social Media Marketing By JDTS-Stop Losing Customers to your Competition-JOIN NOW!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className='video-frame'>
        <iframe className='rounded shadow' width="300" src="https://www.youtube.com/embed/SgcMglFFeRA" title="Digital Marketing/Social Media Marketing By JDTS-Stop Losing Customers to your Competition-JOIN NOW!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className='video-frame'>
        <iframe className='rounded shadow' width="300" src="https://www.youtube.com/embed/cX8vI_r5Ekc?s" title="Digital Marketing/Social Media Marketing By JDTS-Stop Losing Customers to your Competition-JOIN NOW!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className='video-frame'>
        <iframe className='rounded shadow' width="300" src="https://www.youtube.com/embed/kBvSdaxfAas?s" title="Digital Marketing/Social Media Marketing By JDTS-Stop Losing Customers to your Competition-JOIN NOW!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className='video-frame'>
        <iframe className='rounded' width="300" src="https://www.youtube.com/embed/EOHxyr16IJs" title="Digital Marketing/Social Media Marketing By JDTS-Stop Losing Customers to your Competition-JOIN NOW!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className='video-frame'>
        <iframe className='rounded' width="300" src="https://www.youtube.com/embed/AnxJlgv0ozk?si" title="Digital Marketing/Social Media Marketing By JDTS-Stop Losing Customers to your Competition-JOIN NOW!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
}
    </>
  );
}
